import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { Box } from 'grommet';
import { Flow } from 'components/Flow/Flow';

/*import { GeometricEdge } from 'components/Flow/Geometric/GeometricEdge';
import { CircuitEdge } from 'components/Flow/Circuit/CircuitEdge'; */

import { ConnectionLine } from 'components/Flow/components/ConnectionLine';

import {
   ReactFlowProvider,
   Node,
   // addEdge,
   // Connection,
   // Edge,
   // OnEdgesChange,
   // OnNodesChange,
} from 'react-flow-renderer';

import { saveDevicePosition } from 'api/device';
import { DummyNode } from 'components/Flow/Dummy/DummyNode';
import { useFlowElements } from 'utils/hooks/useFlowElements';
import { DeviceNode } from 'components/Flow/Node/DeviceNode';
import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';
import { DeviceEdge } from 'components/Flow/Edge/DeviceEdge';
//import { Close, Contact } from "grommet-icons";

/* type TElements = {
   nodes, 
   edges,
   onNodesChange: OnNodesChange,
   onEdgesChange: OnEdgesChange,
   setEdges: React.Dispatch<React.SetStateAction<Edge<Edge<any>[]>[]>>;
} */

let nodeType : {
   deviceNode : (props) => JSX.Element ,
   markerNode : (props) => JSX.Element ,
}
let edgeType : {
   customEdge : (props) => JSX.Element ,
};

let flowInstance;

export const ProjectFlow = observer ((props: {devices}) => {
   
   const { idSchematic } = useContext(ProjectContext);

   nodeType = useMemo (() => ({ deviceNode: DeviceNode, markerNode: DummyNode }), []);

   if (idSchematic      === 1) {
      edgeType = useMemo (() => ({ customEdge: DeviceEdge }), []);
   }
   /* else if (idSchematic === 2) {
      edgeType = useMemo (() => ({ customEdge: CircuitEdge }), [idSchematic]);
   } */

   const reactFlowWrapper = useRef (null);

   const onInit = (reactFlowInstance) => {
      flowInstance = reactFlowInstance;
   };

   const {
      nodes         ,
      edges         ,
      onNodesChange ,
      onEdgesChange ,
      //setEdges
   } = useFlowElements({
      reactFlowInstance : flowInstance     ,
      reactFlowWrapper  : reactFlowWrapper ,
      devices           : props.devices    ,
   });

   //const [fullScreen, setFullScreen]   = useState<boolean>(false);

   // TODO: useViewport
   //const { x, y, zoom } = useViewport();
   /* useEffect(() => {
      console.log(x, y, zoom);
   }, [x, y, zoom]); */
   //console.log(flowInstance?.getZoom());

   /* const onConnect = useCallback(

      (connection: Connection) => {

         setEdges((eds) => addEdge({ ...connection, 
                                    type: "customEdge", 
                                    data: {reactFlowInstance: flowInstance,
                                          reactFlowWrapper:  reactFlowWrapper,
                                          path: []}},
                                    eds))
      }, [flowInstance]
   ); */

   const setDevicePosition = useCallback((_: MouseEvent, node: Node) => {

      saveDevicePosition ({
         id_device       : node.data.device.id_device ,
         id_locationtype : idSchematic                ,
         x               : node.position.x            ,
         y               : node.position.y            ,
      });
   }, [idSchematic]);

   //console.log(edges)
   //console.log(nodes)

   return (

      <Box pad = 'none'>

         { /* fullScreen && (
            <Layer
               onEsc={() => setFullScreen(false)}
               onClickOutside={() => setFullScreen(false)}
            >
                  <Button 
                  icon={<Close />} 
                  onClick={() => setFullScreen(false)}
                  fill={false}
                  style={{ width: "fit-content",
                           position: "absolute",
                           right: 0,
                           zIndex: 5, }}
                  />
               <ReactFlowProvider>
                  <Box width="100vw" height="100vh">
                  <Flow 
                     nodes={nodes} 
                     edges={edges} 
                     nodeTypes={nodeType} 
                     edgeTypes={edgeType} 
                     onNodesChange={onNodesChange} 
                     onEdgesChange={onEdgesChange} 
                     onConnect={onConnect}
                     onNodeDragStop={setDevicePosition}
                     connectionLineComponent={ConnectionLine}
                  />
                  </Box>
               </ReactFlowProvider>
            </Layer>
         ) */ }

         <ReactFlowProvider>

            <Box
               width     = '100%'
               style     = { { maxHeight: '90vh' } }
               height    = 'xlarge'
               className = 'reactflow-wrapper'
               ref       = { reactFlowWrapper }
            >
               <Flow
                  nodes            = { nodes             }
                  edges            = { edges             }
                  nodeTypes        = { nodeType          }
                  edgeTypes        = { edgeType          }
                  onNodesChange    = { onNodesChange     }
                  onEdgesChange    = { onEdgesChange     }
                  //onConnect      = {onConnect          }
                  onNodeDragStop   = { setDevicePosition }
                  connectionLineComponent = { ConnectionLine }
                  //setFullScreen  = { setFullScreen     }
                  //fullScreen     = { fullScreen        }
                  //setEdgeControl = { setEdgeControl    }
                  //edgeControl    = { edgeControl       }
                  onInit           = { onInit            }
               />
            </Box>

         </ReactFlowProvider>
      </Box>
   )
}
);
