import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Tip } from 'grommet';
import { Handle } from 'react-flow-renderer';

import { TPin } from 'components/Flow/Node/hooks/types';

import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';

const Style32 = {
   background: 'black',
   cursor: 'pointer',
   width : 20,
   height: 20,
};

const Style36 = {
   background: 'black',
   cursor: 'pointer',
   width : 22,
   height: 22,
};

export const Button = observer((props: {
   position ,
   device   ,
   pin: TPin,
   style    ,
}) => {

   const {
      device,
      pin,
      position,
   } = props;

   const { setPinValues  } = useContext(ProjectContext);

   const [value, setValue] = useState <number>      ();
   const [style, setStyle] = useState <CSSProperties>(pin.slotType == 36 ? Style36 : Style32);

   useEffect(() => {

      if (value != undefined) {

         setPinValues((prev) => {

            const newPin = {
               device: device.id_device,
               unit  : pin.nunit       ,
               pin   : pin.pin.npin    ,
               value : value           ,
            }

            return prev.some (valuePin => valuePin.device == device.id_device && 
                                             valuePin.pin == pin.pin.npin)
                  ? prev.map ((valuePin) => (valuePin.pin == pin.pin.npin ? newPin : valuePin))
                  : [...prev, newPin];
         });
      }
   }, [value])

   useEffect(() => {

      setStyle({
         ...style      ,
         ...props.style,
         top: props.style.top + (pin.slotType == 36 && - 14),
         background: (pin.slotType == 36 ? 'none' : 'black'),
         border: 'none'
      })
   }, [props.style])

   //console.log(style)

   return (
      <>
         <Tip
            content = { `${pin.pin.typedescription}` 
                        + ((pin.nunit !== undefined) 
                           ? ` - PORT[${pin.nunit}][${pin.nport}]` 
                           : '') }
         >
            <Handle
               id          = { 'pin-' + pin.pin.id_unitslotpin.toString() }
               type        = 'source'
               position    = { position                                   }
               style       = { style                                      }
               onMouseDown = { () => setValue(1)                          }
               onMouseUp   = { () => setValue(0)                          }
            />
         </Tip>
      </>
   )
})
