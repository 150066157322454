import { api } from "api/client";
import { TLinkedHardware, THardware } from "models/Hardware";

export const getHardwareList = async (): Promise<THardware[]> => {
   return await api.send("GET", '/hardware/');
};

export const getAvailableHardwareList = async (
   idUnit: number
): Promise<TLinkedHardware[]> => {
   return await api.send("GET", `/hardware/?id_unit=${idUnit}`);
};

export const getHardwareItem = async (
   HardwareId: number
): Promise<THardware> => {
   return await api.send("GET", `/hardware/?id_hardware=${HardwareId}`);
};

export const editHardwareName = async (
   HardwareId: number,
   Name: string
): Promise<THardware> => {
   return await api.send('PUT', '/hardware/', 
      { 
         id_hardware: HardwareId,
         name       : Name
      });
};

export const editHardwareDescription = async (
   HardwareId  : number,
   Description: string
): Promise<THardware> => {
   return await api.send('PUT', '/hardware/', 
      { 
         id_hardware: HardwareId,
         description: Description
      });
};