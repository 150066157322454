import { TLinkItem, TLinkNewData }   from "models/DeviceLink";
import { TDeviceItem } from "models/Device";
import { api } from "api/client";

export const getDeviceLinks = async (
  deviceId: TDeviceItem["id_device"]
): Promise<TLinkItem[]> => {
  return await api.send("GET", `/device/unit/slot/link/?id_device=${deviceId}`);
};

export const getDeviceLinkItem = async (
  slotId: TLinkItem["id_slotlink"]
): Promise<TLinkItem> => {
  return await api.send("GET", `/device/unit/slot/link/?id_slotlink=${slotId}`);
};

export const createDeviceLinkItem = async (
  newLinkData: TLinkNewData
): Promise<TLinkItem> => {
  return await api.send("POST", "/device/unit/slot/link/", newLinkData);
};

export const editDeviceLinkItem = async (
  linkId: TLinkItem["id_slotlink"],
  remark?: TLinkItem["remark"]
): Promise<TLinkItem> => {
  return await api.send("PUT", "/device/unit/slot/link/", {
    id_slotlink: linkId,
    remark: remark
  });
};

export const deleteDeviceLinkItem = async (
  linkId: TLinkItem["id_slotlink"]
): Promise<TLinkItem> => {
  return await api.send("DELETE", "/device/unit/slot/link/", {
    id_slotlink: linkId,
  });
};