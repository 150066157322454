import React from "react";

import { editHardwareName } from "api/hardware"
import { EditableBox      } from "components/UI/EditableBox/EditableBox"
import { hardwareStore    } from "store/HardwareStore"

export const ResultName = ({ hardware, name }) => {

   const saveValue = ( value ) => {

      editHardwareName (hardware, value != '' ? value : name)
      .then(() => hardwareStore.getHardwares ())
   }

   return (
      <EditableBox
         key       = { hardware }
         value     = { name }
         saveValue = { saveValue }
      />
   )
} 