import React, { useContext } from 'react';

import { observer } from 'mobx-react';
import { deviceStore } from '../../../store/DeviceStore';

import { Box, Text } from 'grommet';
import { Package as IconPackage } from 'grommet-icons';

import { Loader } from '../../Common/Loader';
import { DeviceTable } from './ProjectArea/ProjectTable';
import { ProjectFlow } from './ProjectArea/ProjectFlow';
import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';

export const ProjectArea = observer(() => {

   const { idSchematic } = useContext(ProjectContext);

   if (deviceStore.loading) return <Loader />;
   else {

      return deviceStore.devices.length 
      ? (

         <Box pad = 'none'>

            { idSchematic == 1 &&

            <Box 
               margin = { { vertical: 'medium' } }
            >
               <ProjectFlow
                  devices = { deviceStore.devices } 
               />
            </Box>
            }

            { idSchematic == 3 && 

            <Box 
               margin = { { vertical: 'medium' } }
            >
               <DeviceTable 
                  devices = { deviceStore.devices } 
               />
            </Box>
            }
         
         </Box>

      ) : (

         <Box
            align   = 'center'
            justify = 'center'
            margin  = 'xlarge'
         >

            <IconPackage />
            <Text>
               Нет устройств
            </Text>

         </Box>

      )
   }
}
);
