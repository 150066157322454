import React, { useState } from 'react'
import { Modal } from 'components/UI/Modal/Modal'
import { Box, Button, Form, FormField, Select } from 'grommet'
import { workspaceStore } from "store/WorkspaceStore"
import { rootStore } from 'store/RootStore'
import { useNavigate } from "react-router-dom"

export type TModal = {
   show   : boolean
   config?: any
};

type TValue = {
   key: number,
   name: string
} 

export const WorkspaceModal = (props: {
   modal   : TModal
   setModal: React.Dispatch<React.SetStateAction<TModal>>
}) => {

   const navigate = useNavigate()
   const { modal, setModal } = props;
   const [value , setValue] = useState <TValue>(
      {
         key: rootStore.currentUserStore.currentUserData.id_workspace,
         name: rootStore.currentUserStore.currentUserData.workspacename
      }
   )

   //console.log(value);

   return (
      <Modal
         modal    = { props.modal }
         setModal = { props.setModal }
         heading  = 'Выберите рабочее пространство'
      >
         <Form
            onSubmit = { () => {
               workspaceStore.changeWorkspace (value.key)
               navigate ("/")
            } }>

            <FormField name = 'config'>
               <Select
                  options  = { workspaceStore.workspaces.map (workspace => 
                  { return (
                     { 
                        key: workspace.id_workspace,
                        name: workspace.name 
                     })
                  }) }
                  value    = { value }
                  valueKey = { option => option.key }
                  labelKey = 'name'
                  onChange = { ({ value }) => setValue (value) }
               />
            </FormField>

            <Box
               direction = 'row'
               justify   = 'between'
            >
               <Button
                  primary
                  type  = 'submit'
                  label = 'Перейти'
                  fill  = { false } 
               />
               <Button
                  label = 'Отмена'
                  onClick = { () => setModal({ ...modal, show: false }) }
                  fill = { false }
               />

            </Box>

         </Form>
      </Modal>
   );
};
