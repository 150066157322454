import { api } from "api/client";
import { TPinItem } from "models/Pins";

export const getPinList = async (
   unitSlotId: number
): Promise<TPinItem[]> => {
   return await api.send("GET", `/device/unit/slot/pin/?id_unitslot=${unitSlotId}`);
};

export const getPinItem = async (
   unitSlotPinId: number
): Promise<TPinItem> => {
   return await api.send("GET", `/device/unit/slot/pin/?id_unitslotpin=${unitSlotPinId}`);
};