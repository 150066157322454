import { useState, useEffect, SetStateAction } from 'react';
import { TProjectItem } from '../../models/Project';

export const useAsyncFn: (
   apiFunc: (param: number) => Promise<TProjectItem>,
   param: number
) => TProjectItem | undefined = (apiFunc, param) => {
   const [state, setState] = useState<TProjectItem | undefined>();

   useEffect(() => {
      let isLoaded = false;
      apiFunc(param).then(
         (response: SetStateAction<TProjectItem | undefined>) => {
            if (!isLoaded) {
               setState(response);
            }
         }
      );
      return () => {
         isLoaded = true;
      };
   }, [apiFunc, param]);

   return state;
};
