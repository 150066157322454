import { makeAutoObservable } from "mobx"
import { TAuthRequest } from "models/Auth"
import { auth, refresh } from "api/auth"

import {
   getLocalStorageItem,
   removeLocalStorageItem,
   setLocalStorageItem,
} from "utils/localStorage"

import { ACCESS_TOKEN } from "constants/globalConstants"
import { rootStore } from "store/RootStore"
import { isOuter } from "data/routes";

export class AuthStore {

   loading = false

   constructor () {
      makeAutoObservable(this)
   }

   async login (formData: TAuthRequest) {

      this.setLoading (true)

      return await auth (formData)
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)

         if (getLocalStorageItem (ACCESS_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ()
         }
      })
      .finally(() => {
         this.setLoading (false)
      })
   }

   async refresh () {

      this.setLoading (true)

      return await refresh ()
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)

         if (getLocalStorageItem (ACCESS_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ()
         }
      })
      .finally(() => {
         this.setLoading (false)
      })
   }

   logout () {

      removeLocalStorageItem (ACCESS_TOKEN)

      if (!isOuter (window.location.pathname) &&
          !location.pathname.includes ('/code/')) window.location.replace ("/login")
   }

   setLoading = (isLoading: boolean) => {
      this.loading = isLoading
   }
}
