import React from "react";
import { observer } from "mobx-react";

import { Box, Grid } from "grommet";

import { DeviceCard } from "../../DeviceCard";
import { TDevice } from "models/Device";

export const DeviceTable = observer((props: { devices: TDevice[] }) => {

   return (
      <Box pad = { { top: "45px" } }>

         <Grid
            columns   = "medium"
            gap       = "small"
            className = "main"
         >

            { props.devices.map(device => 

               <DeviceCard
                  device = { device }
                  key    = { "device-" + device.id_device } 
               />
            ) }

         </Grid>
      </Box>
   )
})