import { makeAutoObservable, toJS } from 'mobx';
import { errorHandler } from '../utils/errorHandler';
import { TProjectLink } from 'models/ProjectLink';
import {
   getProjectLinks,
   addProjectLinkPin,
   addProjectLink,
   deleteProjectLinkPin,
   editProjectLink,
} from 'api/projectlink';

import { toast } from 'react-toastify';

class ProjectLinkStore {
   loading = true;
   links: TProjectLink['links'];
   idProject: number;
   idSchematic: number;

   constructor () {
      makeAutoObservable(this);
   }

   setLinks (links: TProjectLink['links']) {
      this.links = links;
      this.loading = false;
      //console.log ("Links is loaded")
      //console.log (toJS(this.links))
   }

   getProjectLinks = async (idProject: number, idSchematic: number) => {
      try {
         const result = await getProjectLinks(idProject, idSchematic);
         if (
            result.id_project == idProject &&
            result.id_schematictype == idSchematic
         ) {
            this.setLinks(result.links);
            this.idProject = idProject;
            this.idSchematic = idSchematic;
         }
      } catch (err) {
         errorHandler(err);
      }
   };

   addPinLink = (name, id_device, id_unitslotpin) => {
      const linkData = {
         id_project: this.idProject,
         id_schematictype: this.idSchematic,
         name: name,
         id_device: id_device,
         id_unitslotpin: id_unitslotpin,
      };

      addProjectLink(linkData).then((result) => {
         if (
            result.id_project == this.idProject &&
            result.id_schematictype == this.idSchematic
         ) {
            this.setLinks(result.links);
            toast.success('Связь создана');
            return result;
         }
      });
   };

   editPinLink = (name, id_pinlink) => {
      const linkData = {
         id_project: this.idProject,
         id_schematictype: this.idSchematic,
         id_pinlink: id_pinlink,
         name: name,
      };

      editProjectLink(linkData).then((result) => {
         if (
            result.id_project == this.idProject &&
            result.id_schematictype == this.idSchematic
         ) {
            this.setLinks(result.links);
            toast.success('Связь успешно переименована');
            return result;
         }
      });
   };

   addPinToLink = (id_pinlink, id_device, id_unitslotpin) => {
      const linkData = {
         id_project: this.idProject,
         id_schematictype: this.idSchematic,
         id_pinlink: id_pinlink,
         id_device: id_device,
         id_unitslotpin: id_unitslotpin,
      };

      addProjectLinkPin(linkData).then((result) => {
         if (
            result.id_project == this.idProject &&
            result.id_schematictype == this.idSchematic
         ) {
            this.setLinks(result.links);
            //toast.success("Пин добавлен к связи")
            return result;
         }
      });
   };

   deletePinFromLink = (id_pinlink, name, id_device, id_unitslotpin) => {
      const linkData = {
         id_project: this.idProject,
         id_schematictype: this.idSchematic,
         id_pinlink: id_pinlink,
         id_unitslotpin: id_unitslotpin,
      };

      deleteProjectLinkPin(linkData).then((result) => {
         if (
            result.id_project == this.idProject &&
            result.id_schematictype == this.idSchematic
         ) {
            this.setLinks(result.links);
            //toast.success("Пин удалён из связи")
            return result;
         }
      });
   };
}

export const projectLinkStore = new ProjectLinkStore();
