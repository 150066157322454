import { makeAutoObservable } from "mobx";
import { TLinkItem } from "../models/DeviceLink";
import { getDeviceLinks, getDeviceLinkItem } from "../api/devicelink";
import { errorHandler } from "../utils/errorHandler";


class DeviceLinkStore {
  loading = true;
  links: TLinkItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setLinks(links: TLinkItem[]) {
    this.links = links;
    this.loading = false;
    //console.log ("Links is loaded")    
  }

  getDeviceLinks = async (idUnit: number) => {
    try {
      const result = await getDeviceLinks(idUnit);
      this.setLinks(result);
    } catch (err) {
      errorHandler(err);
    }
  }

  getLinkItem = async (idLink: number) => {
    try {
      const result = await getDeviceLinkItem(idLink);
      return result;
    } catch (err) {
      errorHandler(err);
    }
  }
  
}

export const deviceLinkStore = new DeviceLinkStore();
