import React, { useState } from "react"
import { observer } from "mobx-react"

import { Box, Select } from "grommet"

import { TProjectItem } from "models/Project"
import { TDevice } from "models/Device"
import { TUnit } from "models/Unit"

type SelectProps = {
   placeholder: string
   labelKey   : string
   valueKey   : string
   options    : Array<TProjectItem | TDevice | TUnit>
   value?     : string
   setState   : (v: string, c?: string) => void
   getOptions?: { (idProject: number): void }
   default?   : number
};

export const HeaderSelect = observer ((props: SelectProps) => {

   const [options, setOptions] = useState (props.options)
  
   return (
      <Box
         fill
         round  = "xsmall"
         margin = { {
            vertical  : "8px",
            horizontal: "small",
         } }
      >
         <Select
            size         = "small"
            placeholder  = { props.placeholder }
            labelKey     = { props.labelKey }
            //dropHeight={"medium"}
            defaultValue = { props.default }
            valueKey     = { { key: props.valueKey, reduce: true } }
            value        = { props.value }
            options      = { options }
            onClose      = { () => setOptions (props.options) }
            onSearch     = { text => {
               const exp = new RegExp (text, "i");
               setOptions (props.options.filter (o => exp.test(o.name)))
            } }
            onChange     = { ({ value: nextValue }) => {
               props.setState (nextValue)
               props.getOptions?.(nextValue)
            } }
         />
      </Box>
   );
});
