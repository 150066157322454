import {
   TDevice,
   TDeviceItem,
   TDevicePosition,
   TDeviceType,
   TFormCreateDevice,
   TFormEditDevice,
} from 'models/Device';
import { api } from 'api/client';

export const getDeviceTypeList = async (): Promise<TDeviceType> => {
   return await api.send ('GET', '/device/type/');
};

export const getDeviceListInProject = async (
   projectId: number
): Promise<TDevice[]> => {
   return await api.send ('GET', `/device/?id_project=${projectId}`);
};

export const getDeviceItem = async (
   deviceId: number
): Promise<TDevice> => {
   return await api.send ('GET', `/device/?id_device=${deviceId}`);
};

export const getDeviceInfo = async (
   deviceId: number,
   projectId: number
): Promise<TDevice> => {
   return await api.send (
      'GET',
      `/device/?id_device=${deviceId}&id_project=${projectId}`
   );
};

export const createDeviceItemInProject = async (
   data: TFormCreateDevice
): Promise<TDeviceItem> => {
   return await api.send ('POST', '/device/', data);
};

export const editDeviceItem = async (
   data: TFormEditDevice
): Promise<TDeviceItem> => {
   return await api.send ('PUT', '/device/', data);
};

export const deleteDeviceItem = async (
   deviceId: number
): Promise<TDeviceItem> => {
   return await api.send ('DELETE', '/device/', {
      id_device: deviceId,
   });
};

// Flow option
export const saveDevicePosition = async (
   devicePosition: TDevicePosition
): Promise<TDevice> => {
   return await api.send ('PATCH', '/device/', {
      id_device      : devicePosition.id_device,
      id_locationtype: devicePosition.id_locationtype,
      x              : devicePosition.x,
      y              : devicePosition.y,
   });
};