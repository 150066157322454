import React from "react"
import { Box, Button, Heading        } from "grommet"
import { Code, Edit, Previous, Trash } from "grommet-icons/icons"
import { DeleteLayer                 } from "./DeleteLayer"
import { useNavigate, useParams      } from "react-router-dom"

export type TUnitButtons = {
   name: string,
   plc : boolean
}

export function UnitButtons (props: TUnitButtons) {

   const { idProject, idDevice, idUnit } = useParams ()
   const [open, setOpen] = React.useState <boolean> ()
   const onOpen          = () => setOpen (true)
   const onClose         = () => setOpen (undefined)
   const navigate        = useNavigate ()

   return (
      <>
         <Box
            gap       = "small"
            direction = "row"
         >
            <Heading>

               <Button
                  secondary
                  color = "brand" 
                  icon  = { <Previous color = "brand"/> }
                  href  = { `/projects/${idProject}/device/${idDevice}` }
                  style = { { padding: "12px 12px 12px 0" } }
               />

               { props.name }

               <Button
                  secondary
                  onClick = { () => navigate (`/projects/${idProject}/device/${idDevice}/unit/${idUnit}/edit`) }
                  icon    = { <Edit color = "brand" /> }
                  color   = "brand"
                  tip     = "Редактировать"
               />

               <Button
                  secondary
                  icon    = { <Trash color = "buttonDelete" /> }
                  color   = "buttonDelete"
                  onClick = { onOpen }
                  tip     = "Удалить"
               />

               { props.plc &&
                  <Button
                     secondary
                     onClick = { () => navigate (`/projects/${idProject}/device/${idDevice}/unit/${idUnit}/code`) }
                     icon    = { <Code color = "brand" /> }
                     color   = "brand"
                     label   = "Редактор кода"
                  /> 
               }
            </Heading>
         </Box>
         { open && <DeleteLayer closeFunc = { onClose } /> }
      </>
   );
}
