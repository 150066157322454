import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { observer        } from 'mobx-react';

import { Box             } from 'grommet';
import { EmulatorContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';
import { DEC2RGB         } from './function/HEX2RGB.js'
import { TPin } from 'components/Flow/Node/hooks/types.js';

const defaultStyle = {
   width : 224,
   height: 168,
};

let K = 1;

type TScreen = {
   "Command" : string,
   "X"?      : number,
   "Y"?      : number,
   "Font"?   : number,
   "Color"   : number,
   "BGColor"?: number,
   "Text"?   : string,
};

export const Screen = observer ((props: {
   device,
   pin: TPin,
   style,
}) => {

   const {
      device,
      pin,
   } = props;

   const { screens } = useContext (EmulatorContext)

   const [boxStyle   , setBoxStyle    ] = useState <CSSProperties>(defaultStyle);
   const [screenStack, setScreenStack ] = useState <TScreen[]>([]);

   useEffect(() => {

      if (screens.length) {

         const tscreen = screens?.find (screen => screen.device == device.id_device )?.value
         let screen: TScreen

         if (typeof tscreen == "string") screen = JSON.parse (tscreen)

         //console.log(screen)

         if (screen) {

            if (screen.Command == 'FillColor') {
               setScreenStack([])
               setBoxStyle({ ...boxStyle, backgroundColor: `#${DEC2RGB (screen?.Color)}` })
            }
            else if (screenStack.some (screenInStack => screenInStack.X == screen.X && 
                                                        screenInStack.Y == screen.Y &&
                                                        JSON.stringify (screenInStack) != JSON.stringify (screen))) {
               setScreenStack ([...screenStack.filter ((screenInStack: TScreen) => screenInStack.X != screen.X || screenInStack.Y != screen.Y), screen])
            }
            else if (!screenStack.some (screenInStack => JSON.stringify (screenInStack) == JSON.stringify (screen))) {
               setScreenStack ([...screenStack, screen])
            }
         }
      }
      else setScreenStack ([])

   }, [screens])


   useEffect(() => {

      if (pin.slotType == 36) K = 0.345;

      setBoxStyle({
         ...props.style,
         ...boxStyle,
         border  : '1px solid black'            ,
         top     : Number(props.style?.top) + ((pin.slotType == 36) ? -5 : 17),
         position: 'absolute'                   ,
         width   : defaultStyle.width  * K,
         height  : defaultStyle.height * K
      });

   }, [screenStack, props.style]);

   //console.log(screenStack)

   if (screenStack.length) {

      return (
         <>
            <Box
               id    = { 'pin-' + pin.pin.id_unitslotpin.toString() }
               style = { boxStyle                                   }
            >
               <svg
                  width  = { defaultStyle.width * K }
                  height = { defaultStyle.height * K }
               >
                  
                  { screenStack.map ((screen, i) => (

                     (screen.Command == 'WriteString') && (
                        <g 
                           key = { i }
                        >
                           <defs>
                              <filter
                                 x      = "0"
                                 y      = "0"
                                 width  = "1"
                                 height = "1"
                                 id     = { "solid" + i }
                              >
                                 <feFlood
                                    floodColor = { `#${DEC2RGB (screen?.BGColor)}` }
                                    result     = "bg"
                                 />
                                 <feMerge>
                                    <feMergeNode in = "bg"/>
                                    <feMergeNode in = "SourceGraphic"/>
                                 </feMerge>
                              </filter>
                           </defs>

                           { screen?.Y &&
                              <text
                                 x         = { screen?.X * 0.7 * K }
                                 y         = { (screen?.Y + 12) * K }
                                 fontSize  = { 12 * 0.7 * K }
                                 className = 'screen'
                                 style     = { { 
                                    fill: `#${DEC2RGB (screen?.Color)}`,
                                    letterSpacing: 7 * 0.7 * K
                                 } }
                                 filter    = { `url(#solid${i})`                     }
                              >
                                 { screen?.Text }
                              </text>
                           }
                        </g>
                     )
                  )
                  ) }
               
               </svg>
            </Box>
         </>
      );
   }
   else return <></>
});