import { makeAutoObservable } from "mobx"
import { TDevice } from "models/Device"
import { getDeviceListInProject } from "api/device"
import { errorHandler } from "utils/errorHandler"

class DeviceStore {
   loading = true
   devices: TDevice[] = []

   constructor () {
      makeAutoObservable (this)
   }

   setDevices (devices: TDevice[]) {
      this.devices = devices
      this.setLoading (false)
   }

   setLoading = (isLoading: boolean) => {
      this.loading = isLoading
   }

   getDevices = async (idProject: number) => {

      try {
         const result = await getDeviceListInProject (idProject)
         this.setDevices (result)
      } catch (err: unknown) {
         errorHandler (err)
         this.setLoading (false)
      }
   }
}

export const deviceStore = new DeviceStore ()
