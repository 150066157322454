import { api } from "api/client";
import { TProjectLinkProps, TProjectLink, TConflictLink } from "models/ProjectLink";

export const getProjectLinks = async (
   projectId    : number,
   schematicType: number
): Promise<TProjectLink> => {
   return await api.send("GET", `/project/link/?id_project=${projectId}&id_schematictype=${schematicType}`);
};

export const addProjectLink = async (
   data: {
      id_project      : number,
      id_schematictype: number,
      name            : string,
      id_device       : number,
      id_unitslotpin  : number
   }
): Promise<TProjectLink> => {
   return await api.send ("POST", "/project/link/", data);
};

export const editProjectLink = async (
   data: {
      id_project      : number,
      id_schematictype: number,
      id_pinlink      : number,
      name            : string
   }
): Promise<TProjectLink> => {
   return await api.send ("PUT", "/project/link/", data);
}

export const addProjectLinkPin = async (
   data: {
      id_project      : number,
      id_schematictype: number,
      id_pinlink      : number,
      id_device       : number,
      id_unitslotpin  : number
   }
): Promise<TProjectLink>  => {
   return await api.send ("PUT", "/project/link/pin/", data);
}

export const deleteProjectLinkPin = async (
   data: TProjectLinkProps
): Promise<TProjectLink>  => {
   return await api.send ("DELETE", "/project/link/pin/", data);
}

export const getConflictProjectLink = async (
   slotId: number,
   unitId: number
): Promise<TConflictLink> => {
   return await api.send ("GET", `/project/link/pin/?id_unit=${unitId}&id_unitslot=${slotId}`);
};
// For constructed links 
/* export const addProjectLinkItem = async (
  data: TProjectLinkItem
): Promise<TProjectLinkItem> => {
  return await api.send("POST", "/project/link/", data);
};

export const editProjectLinkItem = async (
  data: TProjectLinkItem
): Promise<TProjectLinkItem> => {
  return await api.send("PUT", "/project/link/", data);
};

export const deleteProjectLinkItem = async (
  projectId: number
) => {
  return await api.send("DELETE", "/project/link/", { id_project: projectId });
}; */