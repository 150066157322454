import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Button, Text, TextInput } from "grommet";
import { Edit, Save } from "grommet-icons";

export const EditableBox = (props: {value, saveValue, style?}) => {

   const [editButton, setEditButton] = useState (false)
   const [editInput , setEditInput ] = useState (false)
   const [value     , setValue     ] = useState (props.value)

   const boxRef    = useRef (null)
   const inputRef  = useRef (null)

   const closeClick = e => {

      if (e.target !== inputRef.current &&
          e.target !== boxRef.current) {

         setEditInput (false)
         document.removeEventListener ("click"  , closeClick);
      }
   }

   const closePress = useCallback (e => {

      if (e.key === 'Escape') setEditInput (false);
      if (e.key === 'Enter') {

         props.saveValue (value);
         setEditInput (false);
      }
   }, [value])

   useEffect(() => {

      if (editInput) {
         document.addEventListener ("keydown", closePress);
         document.addEventListener ("click"  , closeClick);
   
         return () => {
            document.removeEventListener ("keydown", closePress);
            document.removeEventListener ("click"  , closeClick);
         };
      }
   }, [editInput, closePress]);

   return (
      <Box
         fill         = "horizontal"
         style        = { {  position: 'relative', ...props.style } }
         onMouseEnter = { () => setEditButton (true)  }
         onMouseLeave = { () => setEditButton (false) }
         direction    = "row"
         ref          = { boxRef }
      >
         { editInput 
           ? (
               <>
                  <TextInput 
                     defaultValue = { props.value }
                     onChange     = { e => setValue (e.target.value) }
                     ref = { inputRef }
                  />
                  <Button
                     secondary
                     fill    = { false }
                     icon    = { <Save color = 'brand' /> }
                     style   = { { 
                        padding   : '6px',
                        background: 'white',
                     } }
                     onClick = { () => { 
                        setEditInput (false)
                        props.saveValue (value)
                     } }
                  />
               </>
           )
           : (
               <>
                  <Text>
                     { props.value }
                  </Text>

                  { editButton &&
                     <Button
                        secondary
                        fill    = { false }
                        icon    = { <Edit color = 'brand' /> }
                        style   = { { 
                           padding   : '6px',
                           position  : 'absolute',
                           right     : '5px',
                           top       : '0',
                           bottom    : '0',
                           margin    : 'auto 0',
                           zIndex    : '1000',
                           background: 'white',
                           boxShadow : '0 0 5px 5px white',
                        } }
                        onClick = { () => {
                           setEditInput (true)
                        } }
                     />
                  }
               </>
           )
         }
      </Box>
   )
} 