import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";

import { TSlotItem } from "models/Slot";
import { deviceLinkStore } from "store/DeviceLinkStore";
import { unitStore } from "store/UnitStore";
import { slotStore } from "store/SlotStore";

import { Grid, Box, Layer, Text, Button } from "grommet";
import { Add, Subtract } from "grommet-icons";

import { Loader } from "components/Common/Loader";

import { UnitCreate } from "components/Units/Components/UnitCreate";

import { LinkCreate } from "./Components/LinkCreate";
import { LinkDelete } from "./Components/LinkDelete";
import { getAvaliableSlotsForLink } from "./Components/LinkManage";


export const SlotCardVertical = observer((props: {

   slot  : TSlotItem, 
   idUnit: number

}) => {

   const [showCreateLink, setShowCreateLink] = useState<boolean>()
   const [showDeleteLink, setShowDeleteLink] = useState<boolean>()
   const [ref , setRef ] = useState<number>(0)

   useEffect(() => { !showCreateLink ? setRef(0) : {} }, [showCreateLink])

   const findName = () => {

      let FindSlot: TSlotItem
  
      const linkSlots = deviceLinkStore.links
      ?.find (link => link.id_slotlink === props.slot.id_slotlink)

      const pairingSlot: number = (props.slot.id_unitslot == linkSlots?.id_unitslots[0]) 
      ? linkSlots?.id_unitslots[1] 
      : linkSlots?.id_unitslots[0]

      const JSlots = Object.values(slotStore.slots)

      JSlots.forEach(unit => {

         const findSlot: TSlotItem = unit?.find (slot => slot.id_unitslot === pairingSlot)
         findSlot?.name != undefined ? FindSlot = findSlot : {};
      })   

      if (FindSlot != undefined) return FindSlot?.name
      else                       return ''
   }

   if (deviceLinkStore.loading || slotStore.loading) {

      return <Loader size = "small" />;
   }
   else {
    
      return (

         <Box
            margin     = { { vertical: "2px" } }
            background = { props.slot.id_slotlink != null
                           ? "backgroundSideBar"
                           : "baseBackground" }
         >

            { showCreateLink && (

               <Layer
                  onEsc          = { () => setShowCreateLink (false) }
                  onClickOutside = { () => setShowCreateLink (false) }
               >

                  { !getAvaliableSlotsForLink(props.slot.id_unitslot) &&

                     <UnitCreate
                        setShow = { setShowCreateLink }
                        slot    = { props.slot }
                     />
                  }

                  { getAvaliableSlotsForLink(props.slot.id_unitslot) &&

                     <>

                        { ref == 0 &&

                           <Box margin = "medium">
                              <Box pad = "medium"><Button
                                 primary
                                 gap     = "small"
                                 label   = "Создать новый компонет"
                                 onClick = { () => setRef (1) }
                              />
                              </Box>
                              <Box pad = "medium">
                                 <Button
                                    primary
                                    gap     = "small"
                                    label   = "Установить связь с существующим"
                                    onClick = { () => setRef (2) }
                                 />
                              </Box>
                           </Box> 
                        }

                        { ref == 1 &&

                           <UnitCreate
                              setShow = { setShowCreateLink }
                              slot    = { props.slot }
                           /> 
                        }

                        { ref == 2 &&

                           <LinkCreate
                              setShow = { setShowCreateLink }
                              slot    = { props.slot }
                           />
                        }

                     </> 
                  }

               </Layer>
            ) }

            { props.slot.id_slotlink == null &&

               <Grid
                  key   = { props.slot.id_unitslot }
                  pad   = "xxsmall"
                  gap   = "xxsmall"
                  style = { { background:"#7700cc" } }
               >
                  <Box 
                     direction = "row" 
                     align     = "center"
                  >
                     <Text size = "inter">
                        { props.slot.name }
                     </Text>
                  </Box>

                  <Box background = { "none" }>
                     <Button 
                        primary
                        icon      = { <Add /> } 
                        size      = "small"
                        fill      = { false } 
                        style     = { { borderRadius: "9px", padding: "6px", width: "fit-content" } } 
                        alignSelf = "center"
                        onClick   = { () => setShowCreateLink (true) }
                     />
                  </Box>
               </Grid>
            }

            { props.slot.id_slotlink != null &&

               <Box>

                  { /* Todo: Это бред какой-то */ }

                  { findName() != "XM (m)" &&

                     <Grid
                        key   = { props.slot.id_unitslot }
                        pad   = "xxsmall"
                        gap   = "xxsmall"
                        width = "100px"
                        style = { { zIndex:"10", background:"#2d2d2d" } }
                     >
                        <Box
                           direction = "row"
                           align     = "center"
                           height    = "40px"
                        >
                           <Text size = "inter">
                              { props.slot.name + " -> " + findName() }
                           </Text>
                        </Box>

                        <Box background = { "none" }>
                           <Button 
                              primary
                              label     = "Удалить"
                              icon      = { <Subtract /> } 
                              size      = "small"
                              fill      = { false } 
                              style     = { { borderRadius: "9px", padding: "4px 2px", width: "fit-content" } } 
                              alignSelf = "center"
                              onClick   = { () => setShowDeleteLink (true) }
                           />
                        </Box>
                     </Grid>

                  }

                  { showDeleteLink &&

                     <LinkDelete
                        setShow = { setShowDeleteLink }
                        slot    = { props.slot }
                        idUnit  = { props.idUnit }
                     />
                  }

               </Box>
            }

         </Box>
      );
   }
})
