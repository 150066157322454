import React, {
   useCallback,
   useEffect,
   useState
} from "react"

import { useParams       } from "react-router-dom"

import { observer        } from "mobx-react"
import { projectStore    } from "store/ProjectsStore"
import { deviceStore     } from "store/DeviceStore"
import { unitStore       } from "store/UnitStore"
import { slotStore       } from "store/SlotStore"
import { deviceLinkStore } from "store/DeviceLinkStore"

import { UnitCard        } from "./UnitCard"
import { Loader          } from "../Common/Loader"
import { TUnit           } from "models/Unit"
import { Box, Text, Grid } from "grommet"
import { Package         } from "grommet-icons"

import { objSort         } from "utils/objSort"
import { toJS } from "mobx"

export const UnitsTable = observer(() => {

   const { idProject, idDevice } = useParams ()
   const [internalUnits, setInternalUnits] = useState <TUnit[]> ([])
   const [externalUnits, setExternalUnits] = useState <TUnit[]> ([])

   const rootStore = useCallback ( async () => {

      await deviceStore.getDevices (Number(idProject))
      await unitStore.getUnits (Number(idDevice))
      await deviceLinkStore.getDeviceLinks (Number (idDevice))

   }, [idProject, idDevice])

   useEffect(() => {
      if (idProject && idProject)  rootStore()
   }, [idProject, idDevice, rootStore])

   useEffect(() => {

      slotStore.getDeviceSlots (Number (idDevice))

      if (unitStore.units.length > 0 && !unitStore.loading) {

         const internalUnits: TUnit[] = []
         const externalUnits: TUnit[] = []

         unitStore.units?.forEach (unit => {
            if(unit.isinternal == 1) internalUnits.push (unit)
            else                     externalUnits.push (unit)
         })

         setExternalUnits (externalUnits.sort (objSort ("nposition")))
         setInternalUnits (internalUnits.sort (objSort ("nposition")))

      } else slotStore.loading = false

   }, [unitStore.units])

   //console.log(projectStore.loading, deviceStore.loading, unitStore.loading, slotStore.loading, linkStore.loading)
   console.log (toJS (slotStore.slots))

   if (projectStore.loading ||
       deviceStore.loading  ||
       unitStore.loading    ||
       slotStore.loading    ||
       deviceLinkStore.loading) return <Loader />
   else {

      return unitStore.units.length > 0
      ? (
         <Box
            pad = "none"
         >
            <Grid
               columns   = "small"
               gap       = "large"
               className = { "main" }
               style     = { { overflowX: "auto", marginRight: "50px" } }
            >

               { externalUnits.map (unit => (

                  <UnitCard
                     unit       = { unit }
                     id_project = { Number(idProject) }
                     key        = { unit.id_unit }
                  />
               )) }

            </Grid>
         </Box>
      ) : (
         <Box
            align   = "center"
            justify = "center"
            margin  = "xlarge"
         >
            <Package />
            <Text>Нет компонентов</Text>
         </Box>
      )
   }
})