import React from "react"
import { Box, Button, Heading } from "grommet"
import {
   Code,
   Edit,
   Previous,
   Trash,
} from "grommet-icons/icons"
import { DeleteLayer } from "./DeleteLayer"
import { useNavigate, useParams } from "react-router-dom"

export type TDeviceButtons = {
   name         : string
   plc          : boolean
};

export function DeviceButtons (props: TDeviceButtons) {

   const { idProject, idDevice } = useParams ()
   const [open, setOpen] = React.useState <boolean> ()
   const onOpen          = () => setOpen (true)
   const onClose         = () => setOpen (undefined)
   const navigate        = useNavigate ()

   return (
      <>
         <Box
            gap       = "small"
            direction = "row"
         >
            <Heading margin = "none">

               <Button 
                  secondary
                  icon  = { <Previous color = "brand"/> }
                  color = "brand"
                  href  = { `/projects/${idProject}` }
                  style = { { padding: "12px 12px 12px 0" } }
               />

               { props.name }

               <Button
                  secondary
                  onClick = { () => navigate (`/projects/${idProject}/device/${idDevice}/edit`) }
                  tip     = "Редактировать"
                  icon    = { <Edit color = "brand" /> }
                  color   = "brand"
               />

               <Button
                  secondary
                  tip     = "Удалить"
                  icon    = { <Trash color = "buttonDelete" /> }
                  color   = "buttonDelete"
                  onClick = { onOpen }
               />

               { props.plc &&
                  <Button
                     secondary
                     onClick = { () => navigate (`/projects/${idProject}/device/${idDevice}/code`) }
                     icon    = { <Code color = "brand" /> }
                     color   = "brand"
                     label   = "Редактор кода"
                  /> 
               }

            </Heading>
         </Box>

         { open && <DeleteLayer closeFunc = { onClose } /> }

      </>
   );
}
