import { TRoutes } from 'models/Routes'

import { Login } from 'containers/Auth/Login'
import { Main } from 'containers/Main/Main'
import { ProjectsTable } from '../components/Projects/ProjectsTable'
import { DevicePage } from '../components/Devices/DevicePage'
import { DeviceEdit } from '../components/Devices/DeviceEdit'
import { Page404 } from 'components/Common/Page404'
import { ProjectDetailCard } from '../components/Projects/ProjectDetailCard/ProjectDetailCard'
import { ProjectForm } from '../components/Projects/ProjectForm/ProjectForm'
import { DeviceEditorProvider, UnitEditorProvider } from '../components/Editor/EditorEnvironment'
import { DeviceCreate } from '../components/Devices/DeviceCreate'
import { UnitPage } from 'components/Units/UnitPage'
import { UnitEdit } from 'components/Units/Components/UnitEdit'
import { DeviceLanding } from 'components/Landing/DeviceLanding'

const routes: TRoutes = [
   {
      path: '/',
      component: Main,
   },
   {
      path: '/login',
      component: Login,
      isOuter: true,
   },
   {
      path: '/h/:externalUID',
      component: DeviceLanding,
      //isOuter: true,
   },
   {
      path: '/projects',
      component: ProjectsTable,
   },
   {
      path: '/projects/:idProject',
      component: ProjectDetailCard,
   },
   {
      path: '/projects/new',
      component: ProjectForm,
   },
   {
      path: '/projects/edit/:idProject',
      component: ProjectForm,
   },
   {
      path: '/projects/:idProject/device/new',
      component: DeviceCreate,
   },
   {
      path: '/projects/:idProject/device/:idDevice',
      component: DevicePage,
   },
   {
      path: '/projects/:idProject/device/:idDevice/edit',
      component: DeviceEdit,
   },
   {
      path: '/projects/:idProject/device/:idDevice/unit/:idUnit',
      component: UnitPage,
   },
   {
      path: '/projects/:idProject/device/:idDevice/unit/:idUnit/edit',
      component: UnitEdit,
   },
   {
      path: '/projects/:idProject/device/:idDevice/unit/:idUnit/code',
      component: UnitEditorProvider,
   },
   {
      path: '/projects/:idProject/device/:idDevice/code',
      component: DeviceEditorProvider,
   },
   { 
      path: '*',
      component: Page404 
   },
]

export const isOuter = (pathname: string): boolean => {

   return routes
   .filter (route => route.isOuter)
   .some   (route => route.path === pathname)
}