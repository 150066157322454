import React from "react";

import {
   Box,
   Tip,
   DataTable
} from "grommet"

import {
   StatusGood,
   StatusWarning,
   StatusCritical,
   StatusUnknown
} from "grommet-icons"

import { useFilters        } from 'components/Filter/FiltersContext'
import { Link              } from "react-router-dom"
import { ResultName        } from "./ResultName"
import { ResultDescription } from "./ResultDescription"

const statusIcons = {
   0: <Tip content = 'Новый'       ><StatusUnknown  color = "info"    /></Tip>,
   1: <Tip content = 'Активен'     ><StatusGood     color = "success" /></Tip>,
   2: <Tip content = 'Заблокирован'><StatusWarning  color = "warning" /></Tip>,
   3: <Tip content = 'Удален'      ><StatusCritical color = "error"   /></Tip>,
};

// Преобразование данных для рендера
const columns = [
   /* {
      property: 'software.loader',
      header  : 'Loader',
   },
   {
      property: 'software.os',
      header  : 'OS',
   },
   {
      property: 'software.app',
      header  : 'App',
   }, */
  
   /*{
    property: 'hardware.model',
    header: 'Модель',
    render: datum => (
      <Text a11yTitle={!datum.hardware.model ? 'No value' : undefined}>
        {datum.hardware.model || '--'}
      </Text>
    ),
  }, */
];

export const ServerResults = () => {

   const { filteredResults, selected, setSelected } = useFilters ()

   return (

      <Box overflow = "auto">

         <DataTable
            pin
            sortable
            aria-describedby = "servers-heading"
            data       = { filteredResults }
            primaryKey = "did"
            onSelect   = { nextSelected => setSelected(nextSelected) }
            select     = { selected }
            columns    = { [
               {
                  property: 'did',
                  header  : 'DID',
                  primary : true,
                  align   : 'center',
                  render  : data => (data.did ? data.did : '-'),
               },
               {
                  property: 'id_status',
                  header  : 'Статус',
                  align   : 'center',
                  sortable: false,
                  render  : data => statusIcons[data.id_state],
               },
               /* {
                  property: 'isonline',
                  header  : 'Online',
                  render  : data => (data.isonline
                                      ? (
                                          <Tip content = 'В сети'>
                                             <StatusGoodSmall
                                                size  = "small"
                                                color = "success" 
                                             />
                                          </Tip>
                                      ) : (
                                          <Tip content = 'Не в сети'>
                                             <StatusGoodSmall
                                                size  = "small"
                                                color = "error" 
                                             />
                                          </Tip>
                                      )),      
                  align   : 'center',
                  sortable: false,
               }, */
               {
                  property: 'typename',
                  header  : 'Тип устройства',
               },
               {
                  property: 'name',
                  header  : 'Название',
                  render  : data => (
                     <ResultName
                        key      = { data.id_hardware }
                        hardware = { data.id_hardware }
                        name     = { data.name }
                     />
                  )
               },
               {
                  property: 'description',
                  header  : 'Описание',
                  render  : data => (
                     <ResultDescription
                        key         = { data.id_hardware }
                        hardware    = { data.id_hardware }
                        description = { data.description }
                     />
                  )
               },
               ...columns,
               {
                  property: 'id_device',
                  header  : 'Устройство',
                  align   : 'center',
                  render  : data => (data.id_device ?
                     (
                        <Link
                           to    = { `/projects/${data.id_project}/device/${data.id_device}` } 
                           style = { { whiteSpace: 'nowrap' } }
                        >
                           { data.devicename  }
                        </Link>
                     ) : (
                     '-'
                     )
                  ),
               },
               {
                  property: 'id_project',
                  header  : 'Проект',
                  align   : 'center',
                  render  : data => (data.id_device ?
                     (
                        <Link 
                           to    = { `/projects/${data.id_project}` } 
                           style = { { whiteSpace: 'nowrap' } }
                        >
                           { data.projectname  }
                        </Link>
                     ) : ('-')
                  ),
               },
            ] }
         />

      </Box>
   );
};