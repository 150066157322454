import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"

import {
   TSoftwareCompilation,
   TSoftwareCompilationError,
   TSoftwareCompilationLog
} from "models/SoftwareCode"

import { Box, Button } from "grommet"
import { CaretDownFill, CaretUpFill } from "grommet-icons"

import { Resizable } from "re-resizable"
import { format    } from 'date-fns'

import '../css/editor.css'

export const LogPanel = observer((props: {response: TSoftwareCompilation|string, logTime: number}) => {

   const [close , setClose ] = useState<boolean>(false)
   const [height, setHeight] = useState<number>()
   const [log   , setLog   ] = useState<TSoftwareCompilationLog[]>([])
   const [errors, setErrors] = useState<TSoftwareCompilationError[]>([])
   const [tab   , setTab   ] = useState<number>(0)

   const heightRef = useRef(null)
   const scrollRef = useRef(null)

   useEffect(() => {

      if (close) setHeight(34)
      else       setHeight(300)
   }, [close])

   useEffect(() => {

      if (height <= 35) setClose(true)
      else              setClose(false)
   }, [height])

   useEffect(() => {

      setTab(0)

      let response

      if (typeof props.response != "object") {
         response = { 
            result: { 
               code: 1,
               message: 'Ошибка ответа сервиса компиляции' },
            log: [
               { time: new Date ().valueOf (), level: 2, text: props.response }
            ]
         }
      } else response = props.response

      if (typeof response == "object") {

         if (response.error_text) setLog ([{ text: response.error_text }]);
         else if (response.result != undefined) {

            if (response.result.code == 0) {

               setLog ([
                  { time: props.logTime                     , text: "Compiling..." },
                  ...response.log,
                  { time: new Date ().valueOf (), level: 9  , text: "Build path: " + response.out_path },
                  { time: new Date ().valueOf (), level: 100, text: "Build file: " },
                  { time: new Date ().valueOf (), level: 9  , text: "Compilation completed successfully" }
               ])
            }
            else {

               const logWithPath = [
                  { time: props.logTime                     , text: "Compiling..." },
                  ...response.log, 
                  { time: new Date ().valueOf (), level: 2  , text: response.result.message },
               ]

               if (response.out_path) {
                  logWithPath.push (
                     { time: new Date ().valueOf (), level: 2  , text: "Build path: " + response.out_path },
                     { time: new Date ().valueOf (), level: 100, text: "Build file: " },
                  )
               }

               logWithPath.push (
                  { time: new Date ().valueOf (), level: 2  , text: "Compilation error" }
               )

               setLog (logWithPath);
            }

         } 

         if (response.errors) setErrors (response.errors);

      } else setLog ([{ text: response }]);
   }, [props])

   useEffect(() => {

      if (tab == 0 && scrollRef.current != null) scrollRef.current.scrollIntoView ({ behavior: "smooth" }) 
   }, [log])

   return (
      <>
         { close 
           ? (
               <Box 
                  style = { {
                     position: "absolute",
                     bottom  : "0px"     ,
                     left    : "0px"     ,
                     width   : "100%"    ,
                  } }
               >
                  <Box
                     margin = { {
                        vertical  : "none"  ,
                        horizontal: "medium",
                     } }
                     border     = { true }
                     round      = { { size: "xsmall", corner: "top" } }
                     direction  = "column"
                     background = "backgroundSideBar"
                     gap        = "xsmall"
                  >
                     <Box
                        style     = { { minHeight: "34px" } }
                        direction = "row"
                     >
                        <Button
                           tip     = "Развернуть"
                           onClick = { () => setClose(!close) }
                           icon    = { <CaretUpFill /> }
                           style   = { { padding: "5px" } }
                        />
                        <Box alignSelf = "center">
                           Журнал компиляции
                        </Box>
                     </Box>
                  </Box>
               </Box>
         ) : (
         <Resizable
            size         = { { width: "100%", height: height } }
            onResizeStop = { (e, direction, ref, d) => {
               setHeight(height + d.height);
            } }
            minHeight    = "34"
            enable       = { { 
               top        : true ,
               right      : false,
               bottom     : false,
               left       : false,
               topRight   : false,
               bottomRight: false,
               bottomLeft : false,
               topLeft    : false,
            } }
            ref          = { heightRef }
            style        = { { position: "absolute", bottom: "0px", left: "0px" } }
         >
            <Box
               margin = { {
                  vertical  : "none"  ,
                  horizontal: "medium",
               } }
               round      = { { size: "xsmall", corner: "top" } }
               border     = { true }
               direction  = "column"
               background = "backgroundSideBar"
               fill       = "vertical"
            >
               <Box
                  style     = { { minHeight: "34px" } }
                  direction = "row"
               >
                  
                  <Button
                     tip     = "Свернуть"
                     onClick = { () => setClose(!close) }
                     icon    = { <CaretDownFill /> }
                     style   = { { padding: "5px" } }
                  />

                  <Button
                     onClick   = { () => setTab(0) }
                     className = "logTab"
                     label     = "Журнал компиляции"
                     active    = { tab == 0 }
                  />

                  { errors.length > 0 &&

                  <Button
                     onClick   = { () => setTab(2) }
                     className = "logTab"
                     label     = { "Ошибки компиляции: " + errors.length }
                     active    = { tab == 2 }
                     style     = { { color: "red" } }
                  /> }

                  <Button
                     onClick   = { () => setTab(1) }
                     className = "logTab"
                     label     = "Ответ сервера"
                     active    = { tab == 1 }
                  />

               </Box>

               { tab == 0 && 
                  <Box
                     pad        = "small"
                     overflow   = { { vertical: "scroll" } }
                     background = "white"
                     style      = { { fontSize: "16px" } }
                     fill
                  >
                     { log && log.map((message, i) => 
                        <div key = { i }>
                           <span
                              style = { {
                                 display : "flex",
                                 flexFlow: "row nowrap",
                                 color   : (message.level == 2    ? "red"    : 
                                          message.level == 4    ? "yellow" : 
                                          message.level == 1    ? "blue"   :
                                          message.level == 9    ? "green"  :
                                          message.level == 100 && "green") } }
                           >
                              <div
                                 style = { {
                                    whiteSpace: "nowrap",
                                    marginRight: "10px" 
                                 } }
                              >
                                 { message.time && format (message?.time, "dd.MM.yyyy HH:mm:ss.SSS") }
                              </div>

                              { (typeof props.response == "object") && message.level == 100

                              ?  (
                              <div>
                                 { message.text }
                                 <a
                                    href  = { `https://admin.uniport.pro/Emulator/cviewer.php?path=${props.response.out_path}` }
                                    target = "blank"
                                 >
                                 AppSC.cpp
                                 </a>
                                 &nbsp;(
                                 <a
                                    href = { `https://admin.uniport.pro/Emulator/Out/${props.response.out_path}/AppSC.cpp` }
                                    target = "blank"
                                 >
                                       скачать
                                 </a>)
                              </div>
                              
                              ) : (

                              <div>
                                 <pre style = { { margin: "0" } }>
                                    { message.text }
                                 </pre>
                              </div> 
                              ) }

                           </span>
                        </div>
                     ) }

                     <Box
                        height = "1px"
                        ref    = { scrollRef }
                     />
                  </Box> 
               }

               { tab == 2 && 
                  <Box
                     pad        = "small"
                     overflow   = { { vertical: "scroll" } }
                     background = "white"
                     style      = { { fontSize: "16px" } }
                     fill
                  >
                     { errors.map((error, i) => 
                        <div key = { i }>
                           <span>
                              { error.line } { error.position } { error.message }
                           </span>
                        </div>
                     ) }
                  </Box> 
               }

               { tab == 1 && 
                  <Box
                     pad        = "small"
                     overflow   = { { vertical: "scroll" } }
                     background = "white"
                     style      = { { fontSize: "16px" } }
                     fill
                  >
                     <pre>
                        { (typeof props.response == "object") ? JSON.stringify(props.response, null, 2) : props.response }
                     </pre>
                  </Box> 
               }

            </Box>
         </Resizable>

         ) }
      </>
   )
})