import { useEffect, useState } from "react"

import { unitTypeStore } from 'store/UnitTypeStore'

import { Position } from "react-flow-renderer"
import { TPin, TUnit } from "./types"

import { TDevice } from 'models/Device'
import { TUnitImage } from 'models/Device'
import { selectUnit } from "../components/selectUnit"

const defaultUnitBody =
   `<!--width:350-->\r\n
    <g class="layer" id="controller">\r\n
       <title>controller</title>\r\n
       <rect fill="#b3b3b3" height="900" id="plc" width="350" x="0" y="0"/>\r\n
       <rect fill="#000000" height="430" id="svg_3" width="330" x="10" y="235"/>\r\n
       <rect fill="#ffffff" height="70" id="svg_4" width="330" x="10" y="155"/>\r\n
       <rect fill="#ffffff" height="70" id="svg_5" width="330" x="10" y="675"/>\r\n
       <rect fill="#ffffff" height="135" id="svg_6" width="330" x="10" y="755"/>\r\n
       <rect fill="#ffffff" height="135" id="svg_7" width="330" x="10" y="10"/>\r\n
    </g>`;

export const useDeviceNode = (
   device          : TDevice,
   id_schematictype: number,
) => {

   const [units, setUnits] = useState <TUnit[]> ([])
   const [pins , setPins ] = useState <TPin[] > ([])

   useEffect(() => {

      if (device && unitTypeStore.types) {

         const unitImages: TUnit[] = []
         const pins      : TPin[]  = []

         device?.units
         .sort ((a, b) => {

            if (a.id_unittype == 5) return -1
            if (b.id_unittype == 5) return  1
            return 0;
         })
         .forEach ((unit) => {

            let unitBody   = ''
            let unitWidth  = ''
            let unitHeight = ''
            let unitStyle

            if (unit.images.length == 0) {

               unit.images.push ({
                  id_schematictype: 1,
                  image           : defaultUnitBody,
               })
            }

            unit.images
            ?.filter ((unitImage: TUnitImage) => unitImage?.id_schematictype == id_schematictype)
            .forEach ((unitImage) => {

               if (/<!--width:(\d+)-->/.test(unitImage.image)) {
                  unitWidth = unitImage.image?.match  (/<!--width:(\d+)-->/i)[1]
               }

               if (/<!--height:(\d+)-->/.test(unitImage.image)) {
                  unitHeight = unitImage.image?.match (/<!--height:(\d+)-->/i)[1]
               }

               unitBody += unitImage?.image

               unit.slots
               ?.forEach (unitSlot => {
                  
                  unitSlot.images
                  ?.filter (slotImage => slotImage.id_schematictype == id_schematictype)
                  .forEach (slotImage => {

                     unitBody += `<g transform="${slotImage.transform}">${slotImage.image}</g>`

                     const slotPos = slotImage.transform.match (/translate\s*\((\d+\.?\d*?),/)
                     const pinX    = Array.from (slotImage.image.matchAll (/cx="(\d*\.?\d+?)"/g))

                     const actualUnit = selectUnit (unit.id_unittype)
                     const unitProps  = actualUnit (device, slotImage, unitImages)
                     const { deviceSize } = unitProps
                     let pinY  = unitProps.pinY ?? 0
                     unitStyle = unitProps.unitStyle

                     if (!/id="slot_/.test(slotImage.image)) {

                        unitSlot.pins
                        .sort((a, b) => a.npin - b.npin)
                        .forEach((pin, i) => {
            
                           if (slotPos && pinX[i]) {

                              if (unitSlot.id_slottype == 32 ||
                                  unitSlot.id_slottype == 36) {

                                 const slotPosition = slotImage.transform?.match (/translate\s*\(\d+\.?\d*?, (\d+\.?\d*?)\)/i)

                                 const re = new RegExp (`<circle.*id="${pin.fromsvg}".*\/>`, 'i')
                                 const pinDesignator = slotImage.image?.match (re)

                                 let pinPosition: RegExpMatchArray | 0 = 0;
                                 if (pinDesignator) {
                                    pinPosition  = pinDesignator[0]?.match (/cy="(\d*\.?\d+?)"/i)
                                 }

                                 if (slotPosition) pinY = (unitSlot.id_slottype == 32 && 206) + (unitSlot.id_slottype == 36 && 335) + Number (pinPosition[1])
                              }

                              //console.log(pinY)

                              const port = device.ports?.find (port => 
                                 port.id_unitslotpin == pin.id_unitslotpin
                              )

                              const pinProps = {
                                 pin     : pin,
                                 slotType: unitSlot.id_slottype,
                                 unitPos : deviceSize || 0,
                                 slotPos : Number (slotPos[1]),
                                 pinX    : Number (pinX[i][1]),
                                 pinY    : pinY,
                                 nunit   : port?.nunit,
                                 nport   : port?.nport,
                              }

                              switch (unitSlot.id_slotlocation) {
                                 case 2:
                                    pinProps['position'] = Position.Left
                                    break
                                 case 3:
                                    pinProps['position'] = Position.Right
                                    break
                                 case 4:
                                    pinProps['position'] = Position.Top
                                    break
                                 case 5:
                                    pinProps['position'] = Position.Bottom
                                    break
                                 default:
                                    break
                              }

                              pins.push (pinProps)
                           }
                        })
                     }
                  })
               })

               unitImages.push ({
                  unit      : unit,
                  unitWidth : Number (unitWidth),
                  unitHeight: Number (unitHeight),
                  unitBody  : unitBody,
                  unitStyle : unitStyle,
               })
            })
         })

         setUnits (unitImages)
         setPins  (pins)
      }
   }, [device, unitTypeStore.types])

   /* useEffect(() => {
      document.getElementById('pin_1')
   }, []) */

   //console.log(pins)
   return { units, pins }
}