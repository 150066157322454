import React, { useState, useEffect, useContext, CSSProperties } from "react";
import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';
import { Box, Text, Tip } from "grommet";
import { Position } from "react-flow-renderer";

type TStyleProps = {
   value: CSSProperties
}

export const PinValue = (props: {pin, pinValue, valueBackground, defaultStyle, position}) => {

   const {
      pin,
      pinValue,
      valueBackground,
      defaultStyle,
      position
   } = props

   const [style, setStyle] = useState <TStyleProps> ();

   const {
      highlightPins,
   } = useContext (ProjectContext);

   useEffect(() => {

      switch (position) {
         case Position.Top:

            setStyle({
               value: {
                  ...defaultStyle?.value,
                  left: defaultStyle?.pin.left - 30,
                  top : defaultStyle?.pin.top - 60,
                  transform : 'rotate(-90deg)',
               },
            });
            break;
               
         case Position.Bottom:

            setStyle({
               value: {
                  ...defaultStyle?.value,
                  left  : defaultStyle?.pin.left - 30,
                  top: defaultStyle?.pin.top + 40,
                  transform: 'rotate(90deg)',
               },
            });
            break;

         case Position.Left:

            setStyle({
               value: {
                  ...defaultStyle?.value,
                  left: defaultStyle?.pin.left - 80,
                  top : defaultStyle?.pin.top - 8,
                  bottom: 'none',
                  transform: 'rotate(0deg)',
               },
            });
            break;

         case Position.Right:

            setStyle({
               value: {
                  ...defaultStyle?.value,
                  left: defaultStyle?.pin.left + 30,
                  top : defaultStyle?.pin.top - 8,
                  bottom: 'none',
                  transform: 'rotate(0deg)',
               },
            });
            break;
      }
   }, [])

   //console.log (style)

   return (
      <Tip content = { pinValue }>
         <Box
            direction = 'row'
            style = { style?.value }
         >
            <Box
               border = { highlightPins.some ((hpin) => hpin.pin == pin.pin.id_unitslotpin)
                          ? [
                             {
                                color : 'transparent'     ,
                                size  : '8px'             ,
                                style : 'dotted'          ,
                                side  : 'all'             ,
                             },
                             {
                                color : 'red'             ,
                                size  : '6px'             ,
                                style : 'solid'           ,
                                side  : 'right'           ,
                             },
                          ]
                          : [
                             {
                                color : 'transparent'     ,
                                size  : '8px'             ,
                                style : 'dotted'          ,
                                side  : 'all'             ,
                             },
                             {
                                color : 'backgroundRevers',
                                size  : '6px'             ,
                                style : 'solid'           ,
                                side  : 'right'           ,
                             },
                          ] }
            />
            <Box
               pad        = { { vertical: '0px', horizontal: '3px' } }
               background = { valueBackground }
               border     = { highlightPins.some ((hpin) => hpin.pin == pin.pin.id_unitslotpin)
                              ? { color: 'red', size: 'xsmall' }
                              : { color: 'backgroundRevers', size: 'xsmall' } }
            >
               <Text
                  color = { highlightPins.some ((hpin) => hpin.pin == pin.pin.id_unitslotpin)
                            ? 'red'
                            : 'backgroundRewers' }
                  size  = 'xsmall'
                  style = { {
                     fontSize  : '13px',
                     lineHeight: '14px',
                  } }
               >
                  { pinValue }
               </Text>
            </Box>
            <Box
               border = { highlightPins.some((hpin) => hpin.pin == pin.pin.id_unitslotpin)
                          ? [
                             {
                                color : 'transparent'     ,
                                size  : '8px'             ,
                                style : 'dotted'          ,
                                side  : 'all'             ,
                             },
                             {
                                color : 'red'             ,
                                size  : '6px'             ,
                                style : 'solid'           ,
                                side  : 'left'            ,
                             },
                          ]
                          : [
                             {
                                color : 'transparent'     ,
                                size  : '8px'             ,
                                style : 'dotted'          ,
                                side  : 'all'             ,
                             },
                             {
                                color : 'backgroundRevers',
                                size  : '6px'             ,
                                style : 'solid'           ,
                                side  : 'left'            ,
                             },
                          ] }
            />
         </Box>
      </Tip>
   )
}