import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
   deleteLinkedHardware,
   getLinkedHardware,
   getUnitItem
} from "api/unit"
import { TUnit                  } from "models/Unit"
import { TLinkedHardware        } from "models/Hardware"

import { Box, Paragraph, Button } from "grommet"

import { Page404                } from "../Common/Page404"
import { Loader                 } from "../Common/Loader"
import { UnitButtons            } from "./Components/UnitButtons"
import { ChooseHardware         } from "./Components/ChooseHardware"


export const UnitPage = () => {

   const { idUnit } = useParams()

   const [unit          , setUnit          ] = useState <TUnit>()
   const [errorFlag     , setErrorFlag     ] = useState <boolean>()
   const [hardware      , setHardware      ] = useState <TLinkedHardware>()
   const [chooseHardware, setChooseHardware] = useState ({ show: false })
   
   const deleteChooseHardware = () => {

      deleteLinkedHardware (Number (idUnit))
      .then (response => setHardware (response))
   }

   useEffect(() => {

      const fetchUnitData = async () => {

         const unit = await getUnitItem (Number (idUnit))

         if (unit?.error_text) setErrorFlag (true)
         else                  setUnit (unit)
      }

      setErrorFlag (false)

      fetchUnitData ()
      .catch (console.error)

      getLinkedHardware (Number (idUnit))
      .then (linkedHardware => setHardware (linkedHardware))

   }, [idUnit]);

   return (

      <Box
         fill
         pad    = "medium"
         margin = "medium"
         style  = { { borderBottom: "1px solid #dfe4ec" } }
      >

         { unit 
            ? (
               <>
                  <UnitButtons
                     name      = { unit.name }
                     plc       = { true }
                  />

                  <Paragraph fill>
                     <b>Описание:</b> { unit.remark || unit.description }<br />
                     <b>Дата создания:</b> { new Date (unit.dset).toLocaleString () }<br />
                     <b>Оборудование: </b> 

                     { hardware 
                       ? (

                          <>
                             { `${ hardware?.name } (${ hardware?.description })` }
                             <Button
                                secondary
                                color   = "brand"
                                label   = "Отвязать"
                                onClick = { deleteChooseHardware }
                                margin  = { { horizontal: 'small' } }
                             /> 
                          </>

                       ) : ( 

                       <Button
                          secondary
                          color   = "brand"
                          label   = "Привязать"
                          margin  = { { horizontal: 'small' } }
                          onClick = { () => setChooseHardware ({ show: true }) }
                       /> 

                       )
                     }
                  </Paragraph>

                  <ChooseHardware
                     show        = { chooseHardware.show }
                     setShow     = { setChooseHardware }
                     hardware    = { hardware }
                     setHardware = { setHardware }
                  />

               </>
            ) 
            :  errorFlag 

               ?  <Page404 />
               :  <Loader  />
         }
      </Box>
   );
}

