import React, { useContext } from 'react';
import {
   Box,
   Button,
   Footer,
   Header,
   Heading,
   Layer,
   ResponsiveContext,
} from 'grommet';
import { FormClose } from 'grommet-icons';

import { useFilters          } from './FiltersContext';
import { FilterCheckBoxGroup } from './FilterCheckBoxGroup';
import { FilterRangeSelector } from './FilterRangeSelector'

interface StringMap { [key: string]: object; }

export const FiltersLayer = () => {

   const size = useContext (ResponsiveContext);

   const {
      applyFilters,
      data,
      filters,
      setFilters,
      setFiltersLayer,
      filterAttributes,
      layerProps,
      previousFilters,
   } = useFilters();

   const { containerProps, contentProps }: StringMap = { ...layerProps };

   const closeLayer = () => {

      // Пользователь не применил новые настройки фильтра, восстанавливаем предыдущее состояние.
      setFilters (previousFilters);
      setFiltersLayer (false);
   };

   return (

      <Layer
         as             = "section"
         position       = { !['xsmall', 'small'].includes (size) ? 'right' : undefined  }
         full           = {  ['xsmall', 'small'].includes (size) ? true    : 'vertical' }
         onClickOutside = { () => closeLayer() }
         onEsc          = { () => closeLayer() }
         { ...layerProps }
      >
         <Box
            fill  = "vertical"
            gap   = "small"
            pad   = { { vertical: 'medium' } }
            width = { { min: !['xsmall', 'small'].includes (size) ? 'small' : undefined, } }
            { ...containerProps }
         >

            <Header
               pad = { { horizontal: 'medium' } }
            >
               <Heading
                  margin = "none"
               >
                  Фильтры
               </Heading>
               <Button
                  icon    = { <FormClose />      }
                  onClick = { () => closeLayer() } 
               />
            </Header>

            <Box
               pad      = "medium"
               flex
               overflow = "auto"
               { ...contentProps }
            >

               { filterAttributes &&
                 filterAttributes.map (attr => {

                    if (attr.filterType === 'CheckBoxGroup') {
                     
                       return (
                          <FilterCheckBoxGroup
                             key  = { attr.property }
                             attr = { attr }
                          />
                       )
                    }

                    if (attr.filterType === 'RangeSelector') {

                       return (
                          <FilterRangeSelector
                             key  = { attr.property }
                             attr = { attr }
                          />
                       )
                    }

                    return null;
                 })
               }

            </Box>

            <Footer
               justify = "between"
               gap     = "small"
               pad     = { { horizontal: 'medium', bottom: 'small' } }
            >
               <Button
                  label   = "Применить"
                  onClick = { () => {
                     applyFilters(data, filters);
                     setFiltersLayer(false);
                  } }
                  primary
               />

               <Button
                  label   = "Сбросить"
                  onClick = { () => {
                     const nextFilters = {};
                     // Удаляет все фильтры, но не применяет это, пока не будет нажата кнопка «Применить фильтры»
                     setFilters(nextFilters);
                  } }
                  secondary
               />

            </Footer>
         </Box>
      </Layer>
   );
};