import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { deviceStore } from "store/DeviceStore";
import { createDeviceItemInProject } from "api/device";
import { createDeviceSoftware } from "api/softwarecode"

import { Box, Heading, Form, FormField, TextInput, Button } from "grommet";
import { toast } from "react-toastify";

type TCreateDeviceEvenet = {
   value: {
      name  : string,
      remark: string,
   };
};

export function DeviceCreate () {

   const navigate      = useNavigate();
   const { idProject } = useParams();

   const createDevice = (event: TCreateDeviceEvenet) => {

      idProject &&
      createDeviceItemInProject({
         id_project    : Number(idProject),
         name          : event.value.name,
         remark        : event.value.remark,
      })
      .then(response => {

         if (!response.error_text) {

            createDeviceSoftware   (response.id_device)
            deviceStore.getDevices (Number(idProject))

            toast.success (`Success created ${response.name ?? ""} device`)
            navigate (`/projects/${idProject}/device/${response.id_device ?? ""}`)
         } 
         else toast.error (response.error_text)
      })
   }

   return (
      <Box
         fill
         pad    = "medium"
         margin = "medium"
      >
         <Heading>
            Создать устройство
         </Heading>
         
         <Form onSubmit = { createDevice }>
            <Box
               width  = "large"
               margin = { { bottom: "large" } }
            >
               <FormField
                  name  = "name"
                  label = "Название"
               >
                  <TextInput
                     required
                     name = "name" 
                  />
               </FormField>

               <FormField
                  name  = "remark"
                  label = "Описание"
               >
                  <TextInput
                     name = "remark"
                  />
               </FormField>
            </Box>

            <Button
               primary
               type  = "submit"
               label = "Создать" 
            />

            <Button
               label   = "Отмена"
               margin  = { { left: "medium" } }
               onClick = { () => navigate (`/projects/${idProject}`) }
            />
         </Form>
      </Box>
   );
}
