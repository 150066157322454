import React, { useCallback, useContext, useEffect, useRef } from "react";
import MonacoEditor, { useMonaco } from "@monaco-editor/react";

import { unilang } from "./unilang/tokens";
import { themeStore } from "store/ThemeStore";

import styled from "styled-components";

import { EditorContext, useEditor } from "./EditorContextProvider";
import { observer } from "mobx-react";
import { TEditorContext, TEditorHook } from "models/Editor";

/* Добавление команды в контекстное меню
  const handleEditorDidMount = (editor, monaco) => {
   editor.addAction({
    id: "myPaste",
    label: "Custom Paste",
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V],
    contextMenuGroupId: "9_cutcopypaste",
    run: editor => {
      alert("Add your custom pasting code here");
    }
  }); 
  editor.focus();
  editor.trigger("", "editor.action.quickCommand");
};*/

type TEditorProps = {
   softwareCode      : TEditorContext['softwareCode'],
   readOnly          : TEditorContext['readOnly'],
   openMenu          : TEditorContext['openMenu'],
   insertText?       : TEditorContext['insertText'],
   setInsertText     : TEditorContext['setInsertText'],
   setSoftwareCode   : TEditorContext['setSoftwareCode'],
   validateInPorts   : TEditorContext['validateInPorts'],
   validateOutPorts  : TEditorContext['validateOutPorts'],
   compilationService: TEditorContext['compilationService'],
}

type TEditorMethods = {
   compilateCode: TEditorHook['compilateCode'],
   saveCode     : TEditorHook['saveCode'],
}

const Monaco = styled (MonacoEditor)`
  position: absolute;
  height  : 100%;
  width   : 100%;
`
export const Editor = observer (() => {

   const {
      softwareCode, setSoftwareCode,
      insertText  , setInsertText,
      readOnly,
      openMenu,
      validateInPorts,
      validateOutPorts,
   }: TEditorProps = useContext (EditorContext)

   const {
      saveCode,
      compilateCode
   }: TEditorMethods = useEditor ()

   const editorRef = useRef (null)
   const monaco    = useMonaco ()

   const validatePort = useCallback (() => {

      const markers = [];
      const model   = editorRef.current?.getModel();

      if (model) {

         // lines start at 1
         for (let i = 1; i < model.getLineCount() + 1; i++) {

            const range = {
               startLineNumber: i,
               startColumn    : 1,
               endLineNumber  : i,
               endColumn      : model.getLineLength (i)
            };

            const Line = model.getValueInRange(range);

            const re = /PORT\[\d+\]\[\d+\]/g;
            let Match;

            while ((Match = re.exec (Line)) != null) {

               //console.log(Match);
               if(validateInPorts && validateOutPorts) {

                  if (!validateInPorts?.includes (Match[0]) && !validateOutPorts?.includes (Match[0])) {

                     markers.push({
                        message        : 'Невалидный порт',
                        severity       : monaco?.MarkerSeverity.Error,
                        startLineNumber: i,
                        endLineNumber  : i,
                        startColumn    : Match.index + 1,            
                        endColumn      : Match.index + Match[0].length + 1
                     });
                  }
               }
            }
         }

         monaco?.editor.setModelMarkers(model, 'owner', markers);
      }
   }, [editorRef.current, validateInPorts, validateOutPorts])

   useEffect(() => {
      if (editorRef.current) validatePort ();
   })

   useEffect (() => {

      if (monaco) {

         // Регистрация языка
         monaco?.languages.register ({ id: 'unilang' });
         monaco?.languages.setMonarchTokensProvider ('unilang', unilang);

         /* Расширение языка
         monaco?.editor.defineTheme('unitheme', {
            base: 'vs',
            inherit: true,
            rules: [
            { token: 'custom-info'  , foreground: '808080' },
            { token: 'custom-error' , foreground: 'ff0000', fontStyle: 'bold' },
            { token: 'custom-notice', foreground: 'FFA500' },
            { token: 'custom-word'  , foreground: '008800' }
            ],
            colors: {
            'editor.foreground': '#000000'
            }
         });
         monaco?.languages.setMonarchTokensProvider('unilang', {
            tokenizer: {
               root: [
                  [/node/, "custom-error" ],
                  [/to/  , "custom-notice"],
                  [/link/, "custom-info"  ],
                  [/\[[a-zA-Z 0-9:]+\]/, "custom-word"],
               ]
            }
         }); */
      }
   }, [monaco])

   useEffect (() => {

      if (monaco) {

         const KM = monaco?.KeyMod;
         const KC = monaco?.KeyCode;
         const editor = editorRef.current

         // Сохранение
         editor?.addCommand (KM.CtrlCmd | KC.KeyS, saveCode )
         editor?.addCommand (KC.F2, saveCode)

         // Эмуляция
         editor?.addCommand (KM.CtrlCmd | KC.KeyE, () => compilateCode ('em', false))
         editor?.addCommand (KC.F5, () => compilateCode ('em', false))

         // Компиляция
         editor?.addCommand (KM.CtrlCmd | KC.KeyB, () => compilateCode ('plc', false))
         editor?.addCommand (KC.F7, () => compilateCode ('plc', false))

         // Отправка
         editor?.addCommand (KM.CtrlCmd | KC.KeyU, () => compilateCode ('plc', true))
         editor?.addCommand (KC.F8, () => compilateCode ('plc', true))
         
      }
   }, [monaco, editorRef.current, softwareCode])

   useEffect (() => {
      const selection = editorRef.current?.getSelection ();
      const insert = { identifier: { major: 1, minor: 1 }, range: selection, text: insertText, forceMoveMarkers: true };
      editorRef.current?.executeEdits ("my-source", [insert]);
      editorRef.current?.focus ();
      setInsertText ("");
   }, [insertText])

   useEffect (() => {
      if (openMenu) {
         editorRef.current?.focus();
         editorRef.current?.trigger("", "editor.action.quickCommand", "");
      }
   }, [openMenu])

   return (
      <Monaco
         defaultValue    = { softwareCode }
         defaultLanguage = "unilang"
         options         = { {
            fontSize         : 16,
            automaticLayout  : true,
            readOnly         : readOnly,
            detectIndentation: false,
            renderWhitespace : 'boundary',
            tabSize          : 3
         } }
         theme    = { themeStore.themeMode === "dark" ? "vs-dark" : "vs-light" }
         onChange = { (text) => {
            setSoftwareCode (text)
            validatePort  ()
         } }
         onMount  = { (editor) => {
            editorRef.current = editor
            editor.focus ()
            if (editorRef.current) validatePort ()
         } }
      />
   )
})
