import React, { useContext } from 'react'
import { rootStore } from 'store/RootStore'

import { Box, Button, Select, Tip } from 'grommet'
import {
   Save,
   Lock,
   Unlock,
   Menu,
   Close,
   Console,
   Cpu,
   Send,
   Book,
   Previous,
   //DocumentConfig,
} from 'grommet-icons'

import { EditorContext, useEditor } from '../EditorContextProvider'
import { observer } from 'mobx-react'
import { TEditorContext, TEditorHook } from 'models/Editor'

type TEditorPanelProps = {
   source            : TEditorContext['source'],
   openMenu          : TEditorContext['openMenu'],
   readOnly          : TEditorContext['readOnly'],
   //modal             : TEditorContext['modal'],
   //setModal          : TEditorContext['setModal'],
   setOpenMenu       : TEditorContext['setOpenMenu'],
   setReadOnly       : TEditorContext['setReadOnly'],
   services          : TEditorContext['services'],
   compilationService: TEditorContext['compilationService'],
}

type TEditorPanelHook = {
   makeAlternate: TEditorHook['makeAlternate'],
   saveCode     : TEditorHook['saveCode'],
   compilateCode: TEditorHook['compilateCode'],
   emValidation : TEditorHook['emValidation'],
   plcValidation: TEditorHook['plcValidation'],
}

export const EditorPanel = observer (() => {

   const {
      source,
      //modal, setModal,
      openMenu,
      readOnly,
      setOpenMenu,
      setReadOnly,
      services,
      compilationService,
   } : TEditorPanelProps = useContext (EditorContext)

   const {
      makeAlternate,
      saveCode,
      compilateCode,
      emValidation,
      plcValidation
   } : TEditorPanelHook = useEditor ()

   //console.log (deviceStore.devices())

   return (

      <Box
         round      = { { size: 'xsmall', corner: 'top' } }
         direction  = 'row'
         background = 'backgroundSideBar'
         border     = { { side: 'bottom' } }
      >
         <Box
            fill
            align     = 'center'
            justify   = 'start'
            direction = 'row'
         >
            <Button
               tip  = 'Назад'
               icon = { <Previous /> }
               href = { `/projects/${source?.id_project}/device/${source?.id_device}` }
            />

            { openMenu ? (

               <Button
                  tip     = 'Закрыть меню'
                  onClick = { () => setOpenMenu (false) }
                  icon    = { <Close /> }
               />

            ) : (

               <Button
                  tip     = 'Открыть меню'
                  onClick = { () => setOpenMenu (true) }
                  icon    = { <Menu /> }
               />

            ) }

            <Button
               tip     = 'Сохранить (Ctrl+S, F2)'
               onClick = { saveCode }
               icon    = { <Save /> }
            />

            <Tip content = { emValidation () ?? 'Эмуляция (Ctrl+E, F5)' }>
               <Box>
                  <Button
                     onClick  = { () => compilateCode ('em', false) }
                     icon     = { <Console /> }
                     disabled = { !source?.isemulable ?? true }
                  />
               </Box>
            </Tip>

            <Tip content = { plcValidation () ?? 'Компиляция (Ctrl+B, F7)' }>
               <Box>
                  <Button
                     onClick  = { () => compilateCode ('plc', false) }
                     icon     = { <Cpu /> }
                     disabled = { (!source?.iscompilable || source?.islock) ?? true }
                  />
               </Box>
            </Tip>

            <Tip content = { plcValidation () ?? 'Скомпилировать и загрузить в микроконтроллер (Ctrl+U, F8)' }>
               <Box>
                  <Button
                     onClick  = { () => compilateCode ('plc', true) }
                     icon     = { <Send /> }
                     disabled = { (!source?.iscompilable || source?.islock) ?? true }
                  />
               </Box>
            </Tip>

            { /* <Button
               tip     = 'Назначить параметры компиляции'
               onClick = { () => setModal({ ...modal, show: true }) }
               icon    = { <DocumentConfig /> }
            /> */ }

            { (Boolean (rootStore.currentUserStore.currentUserData?.isadmin) && services) &&

               <Tip content = 'Выберите сервис компиляции'>
                  <Box
                     style  = { { whiteSpace: 'nowrap', fontWeight: 'bold' } }
                     margin = { { left: 'medium' } }
                  >
                     <Select
                        options  = { services }
                        value    = { compilationService }
                        labelKey = "name"
                        valueKey = "id_compilationservice"
                        onChange = { ({ option }) => makeAlternate (option) }
                        style    = { { padding: '4px 11px' } }
                        size     = "8"
                     />
                  </Box>
               </Tip>

            }

         </Box>

         <Box
            align     = 'center'
            justify   = 'end'
            direction = 'row'
         >

            <Button
               tip    = 'Описание языка'
               href   = { '//docs.google.com/document/d/140IwZm7UJBBXHfge8G4U5g_PGY2vYpSDE9cHeIPrp0s' }
               target = '_blank'
               icon   = { <Book /> }
            />

            { readOnly ? (

               <Button
                  tip     = 'Разрешить редактирование'
                  onClick = { () => setReadOnly(false) }
                  icon    = { <Lock /> }
               />

            ) : (

               <Button
                  tip     = 'Запретить редактирование'
                  onClick = { () => setReadOnly(true) }
                  icon    = { <Unlock /> }
               />

            ) }
         </Box>
      </Box>
   )
})
