import React, { useContext } from 'react';

import { Modal } from 'components/UI/Modal/Modal';
import { Box, Button, Form, FormField, TextInput } from 'grommet';
import { projectLinkStore } from 'store/ProjectLinkStore';
import { ProjectContext } from '../ProjectDetailCard';

export const ProjectDetailCardModal = () => {

   const { 
      modal    ,
      setModal ,
   } = useContext(ProjectContext);

   //console.log(modal);

   return (
      <Modal 
         modal    = { modal    } 
         setModal = { setModal } 
         heading  = 'Введите название связи'
      >
         <Form
            onSubmit = { (e: { value: { name: string } }) => {
               
               switch (modal.action) {

                  case 'addPinLink':

                     projectLinkStore.addPinLink(
                        e.value.name         ,
                        modal.id_device      ,
                        modal.id_unitslotpin ,
                     );
                     break;

                  case 'editPinLink':

                     projectLinkStore.editPinLink(
                        e.value.name     ,
                        modal.id_pinlink ,
                     );
                     break;

                  default:
                     break;
               }

               setModal({ ...modal, show: false });
            } }
         >
            <FormField
               required
               name  = 'name'
               label = 'Название связи'
            >
               <TextInput
                  name = 'name'
                  defaultValue = { modal?.link_name }
               />
            </FormField>

            <Box
               direction = 'row'
               justify   = 'between'
            >
               <Button
                  primary
                  type  = 'submit'
                  label = { modal.action == 'addPinLink' ? 'Создать' : 'Переименовать' }
                  fill  = { false }
               />
               <Button
                  label   = 'Отмена'
                  onClick = { () => setModal({ ...modal, show: false }) }
                  fill    = { false }
               />
            </Box>
         </Form>
      </Modal>
   );
};
