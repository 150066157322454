import React, {
   createContext,
   useCallback,
   useContext,
   useEffect,
   useMemo,
   useState 
} from 'react'

import {
   useParams,
   useSearchParams
} from 'react-router-dom'

import {
   deleteSoftwareFile,
   //saveCompilateConfig,
   getCompilationService
} from 'api/softwarecode'

import { TInnerPort } from 'models/Device'
import { TCompilationService } from 'models/SoftwareCode'

//import { TModal } from './components/EditorModal'

import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage'
import { ACCESS_TOKEN } from 'constants/globalConstants'
import { generateUID } from 'utils/generateUID'

import { toast } from 'react-toastify'
import { TEditorContext, TEditorHook } from 'models/Editor'
import { observer } from 'mobx-react'

const tabId   = generateUID ()
const emPath  = {}
const sources = {}
const ports: { [x: number] : {
   IsDigital: boolean,
   IsIn     : boolean,
} } | unknown  = {}

const sortNunit = (a: TInnerPort, b: TInnerPort) => {

   if (a.nunit < b.nunit)      return -1
   else if (a.nunit > b.nunit) return 1
   else {
      if (a.nport < b.nport)   return -1
      else                     return 1
   }
}

export const EditorContext  = createContext (null)
export const EditorContextProvider = observer (({
   children,
   idSource,
   getSourceInfo,
   changeSourceSoftware,
   compileSourceSoftware,
   createSourceSoftware
}: {
   children             : React.ReactNode
   idSource             : TEditorContext['idSource'],
   getSourceInfo        : (idSource: TEditorContext['idSource']) => Promise<TEditorContext['source']>,
   changeSourceSoftware : TEditorContext['changeSourceSoftware'],
   compileSourceSoftware: TEditorContext['compileSourceSoftware'],
   createSourceSoftware : TEditorContext['createSourceSoftware'],
}) => {

   const [searchParams      , setSearchParams      ] = useSearchParams ()
   const [infoLoading       , setInfoLoading       ] = useState <boolean>(false)
   const [source            , setSource            ] = useState <TEditorContext['source']> ()
   const [idSoftwareCode    , setIdSoftwareCode    ] = useState <TEditorContext['idSoftwareCode']> ()
   const [softwareCode      , setSoftwareCode      ] = useState <TEditorContext['softwareCode']> ()
   const [readOnly          , setReadOnly          ] = useState <TEditorContext['readOnly']> (false)
   const [openMenu          , setOpenMenu          ] = useState <TEditorContext['openMenu']> (false)
   const [insertText        , setInsertText        ] = useState <TEditorContext['insertText']> ()
   const [panelHeight       , setPanelHeight       ] = useState <TEditorContext['panelHeight']> (window.innerHeight - 60)
   const [emulate           , setEmulate           ] = useState <TEditorContext['emulate']> (false)
   const [log               , setLog               ] = useState <TEditorContext['log'] >()
   const [logTime           , setLogTime           ] = useState <TEditorContext['logTime']> ()
   //const [modal             , setModal             ] = useState<TEditorContext['modal']>()
   const [inPorts           , setInPorts           ] = useState <TEditorContext['inPorts']>([])
   const [outPorts          , setOutPorts          ] = useState <TEditorContext['outPorts']>([])
   const [validateInPorts   , setValidateInPorts   ] = useState <TEditorContext['validateInPorts']>([])
   const [validateOutPorts  , setValidateOutPorts  ] = useState <TEditorContext['validateOutPorts']>([])
   const [services          , setServices          ] = useState <TEditorContext['services']> ([])
   const [compilationService, setCompilationService] = useState <TEditorContext['compilationService']> (
      getLocalStorageItem ('compilationService', { 
         name: 'Production',
         id_compilationservice: 0
      })
   )
   
   useEffect(() => {

      setInfoLoading (true)
      getCompilationService ().then (services => setServices (services))

      idSource &&
      getSourceInfo (idSource)
      .then ((source: TEditorContext['source']) => {

         setSource         (source)
         setSoftwareCode   (source.softwarecode)
         setIdSoftwareCode (source.id_softwarecode)

         if (source?.softwarecode !== '') {

            sources[source.id_device] = {
               name         : source?.name,
               inDigitPort  : source?.ports?.filter ((port) =>  port.isdigital &&  port.isin),
               outDigitPort : source?.ports?.filter ((port) =>  port.isdigital && !port.isin),
               inAnalogPort : source?.ports?.filter ((port) => !port.isdigital &&  port.isin),
               outAnalogPort: source?.ports?.filter ((port) => !port.isdigital && !port.isin),
            }

            source.ports.forEach (port =>

               ports[port?.nunit] = source?.ports
               .filter (aport => aport.nunit == port.nunit)
               .reduce ((obj, aport) =>

                  Object.assign (obj, {
                     [aport.nport]: {
                        IsDigital: aport.isdigital,
                        IsIn     : aport.isin,
                     },
                  }), {})
            )
         }

         const regularInPorts  = Object.values (source.ports).flatMap (unit => unit).filter (port => port.isin ).sort (sortNunit)
         const regularOutPorts = Object.values (source.ports).flatMap (unit => unit).filter (port => !port.isin).sort (sortNunit)

         setInPorts  (regularInPorts)
         setOutPorts (regularOutPorts)
   
         setValidateInPorts  (regularInPorts.map  (port => `PORT[${port.nunit}][${port.nport}]`))
         setValidateOutPorts (regularOutPorts.map (port => `PORT[${port.nunit}][${port.nport}]`))
         setInfoLoading      (false)
      })

      if (searchParams.has ('error')) {
         if (searchParams.get ('error') != 'undefined') {

            fetch (`https://kraycom.ru/Emulator/Out/${searchParams.get ('error')}/out.json`)
            .then ((response) => response.json())
            .then ((result  ) => {

               setLogTime (new Date().valueOf())
               setLog     (result)
            })
         }
      }
   }, [getSourceInfo, idSource])

   const value: TEditorContext = useMemo (() => ({
      infoLoading,
      source            , setSource,
      idSource,
      idSoftwareCode    , setIdSoftwareCode,
      softwareCode      , setSoftwareCode,
      changeSourceSoftware,
      compileSourceSoftware,
      createSourceSoftware,
      searchParams      , setSearchParams,
      readOnly          , setReadOnly,
      openMenu          , setOpenMenu,
      insertText        , setInsertText,
      panelHeight       , setPanelHeight,
      emulate           , setEmulate,
      log               , setLog,
      logTime           , setLogTime,
      inPorts           , setInPorts,
      outPorts          , setOutPorts,
      //modal            , setModal,
      services          , setServices,
      validateInPorts   , setValidateInPorts,
      validateOutPorts  , setValidateOutPorts,
      compilationService, setCompilationService,
   }),
   [
      infoLoading,
      source,
      idSource,
      idSoftwareCode,
      softwareCode,
      changeSourceSoftware,
      compileSourceSoftware,
      createSourceSoftware,
      searchParams,
      readOnly,
      openMenu,
      insertText,
      panelHeight,
      emulate,
      log,
      logTime,
      inPorts,
      outPorts,
      //modal,
      services,
      validateInPorts,
      validateOutPorts,
      compilationService,
   ])

   return <EditorContext.Provider value = { value }>{ children }</EditorContext.Provider>
})

export const useEditor = (): TEditorHook => {

   const { idProject } = useParams ()

   const {
      source,
      idSource,
      idSoftwareCode, setIdSoftwareCode,
      softwareCode,
      changeSourceSoftware,
      compileSourceSoftware,
      createSourceSoftware,
      setEmulate,
      setLog,
      setLogTime,
      //modal, setModal,
      compilationService, setCompilationService,
   }: TEditorContext = useContext (EditorContext)

   const makeAlternate = (value: TCompilationService) => {

      setCompilationService (value)
      setLocalStorageItem   ('compilationService', value)
   }

   const saveCode = useCallback (async () => {

      if (!idSoftwareCode) {
         
         createSourceSoftware (idSource)
         .then (code => {
            setIdSoftwareCode (code.id_softwarecode)
            changeSourceSoftware (code.id_softwarecode, softwareCode)
            .then (response => {

               if (response) toast.success ('Saved successfully')
               else          toast.error   ('Save error')
            })
         })
      }
      else {
         changeSourceSoftware (idSoftwareCode, softwareCode)
         .then (response => {

            if (response) toast.success ('Saved successfully')
            else          toast.error   ('Save error')
         })
      }
   }, [idSoftwareCode, softwareCode])

   const compilateCode = (
      mode  : string, 
      isload: boolean, 
   ) => {

      setEmulate (true)
      setLog     ({ log: [{ time: new Date().valueOf(), text: 'Compiling...' }] })
      setLogTime (new Date ().valueOf ())

      if (source?.islock) toast ('Прошивка этого устройства запрещена')
      else {

         compileSourceSoftware (
            idSource,
            softwareCode?.replace (/\r/g, ''),
            JSON.stringify (ports),
            mode,
            isload,
            compilationService.id_compilationservice
         ).then (response => {

            if (response) {

               if (response.error_text) toast.error (response.error_text)
               else {

                  if (response?.result?.code == 0) {

                     emPath[source.id_device] = response.out_path

                     if (mode == 'em') {
                        window.open ('https://kraycom.ru/Emulator/index.html', 'emulation' + tabId)
                        window.addEventListener (
                           'message',
                           receiveMessage,
                           false,
                        )
                     }
                  }
               }

               setLog (response)
            } 
            else toast.error ('Ошибка обращения к сервису эмуляции')
         })
         .finally (() => setEmulate (false))
      }
   }

   const emValidation  = useCallback ((): string | undefined => {
      if (!source?.isemulable)      return 'Для данного устройства не назначены опции компиляции'
   }, [source])

   const plcValidation = useCallback ((): string | undefined => {
      if (!source?.iscompilable)    return 'Для данного устройства не назначены опции компиляции'
      if ( source?.islock === null) return 'К данному устройству не привязана прошивка "Type 3"'
      if ( source?.islock)          return 'Прошивка этого устройства запрещена'
   }, [source])

   const receiveMessage = useCallback (event => {

      if (event.origin == 'https://kraycom.ru' &&
          event.data.action == 'init'          &&
          Object.keys (sources).length) {

         const obj = JSON.parse ( JSON.stringify ({
            action    : 'project',
            id_project: idProject,
            paths     : emPath,
            devices   : sources,
            auth      : JSON.parse (localStorage.getItem (ACCESS_TOKEN))
         }))

         //console.log (obj)

         event.source.postMessage (obj, event.origin);
      }

      if (event.origin == 'https://kraycom.ru' && 
          event.data.terminate) {

         event.data.terminate.forEach (v =>

            deleteSoftwareFile (v)
            .then (response => {

               if (response?.error_text)
                  toast.error ('Ошибка удаления файлов эмуляции')
            })
         );
      }
   }, [sources])

   /* const saveConfig = (config: string) => {

      saveCompilateConfig (Number (idDevice), config)
      .then ((response) => {

         else {
            setDevice     (response)
            toast.success ('Данные конфигурации сохранены')
         }

         setModal ({ ...modal, show: false })
      })
   } */

   return {
      makeAlternate,
      saveCode,
      //saveConfig,
      compilateCode,
      receiveMessage,
      emValidation,
      plcValidation
   }
}