import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { unitStore } from "store/UnitStore";

import { TDevice } from "models/Device";

import { Box, Heading, Paragraph, Button, Layer } from "grommet";
import { Page404 } from "components/Common/Page404";
import { Loader } from "components/Common/Loader";
import { DeviceButtons } from "./components/DeviceButtons";
import { UnitCreate } from "components/Units/Components/UnitCreate";
import { UnitsTable } from "components/Units/UnitsTable";

import { Add } from "grommet-icons";

import { getDeviceInfo } from "api/device";

export const DevicePage = observer(() => {
   const { idDevice, idProject } = useParams();

   const [show     , setShow     ] = useState<boolean>();
   const [device   , setDevice   ] = useState<TDevice>();
   const [errorFlag, setErrorFlag] = useState<boolean>();

   useEffect(() => {

      setErrorFlag (false);

      getDeviceInfo (Number (idDevice), Number (idProject))
      .then (response => {response.error_text ? setErrorFlag (true) : setDevice (response)})

   }, [idProject, idDevice]);

   //console.log(device)

   return (
      <Box
         fill
         pad    = "medium"
         margin = "medium"
         style  = { { borderBottom: "1px solid #dfe4ec" } }
      >

         { device ? (

            <>
               <DeviceButtons
                  name = { device.name }
                  plc  = { (device?.units.some (unit => unit.id_unittypegroup == 1)) }
               />
               
               <Box 
                  style = { {
                     borderBottom: "1px solid #dfe4ec",
                     paddingLeft : "36px"
                  } }
               >

                  <Paragraph
                     fill
                     margin = { { top: "none" } }
                  >

                     { (device.remark && device.remark != "null") && 
                        <>
                           <b>Описание:</b> { device.remark }<br />
                        </>
                     }

                  </Paragraph>
               </Box>

               { device !== undefined && (

                  <>
                     <Heading
                        level = "2"
                        style = { { marginLeft: "34px" } }
                     >
                        Компоненты устройста

                        { (unitStore.units?.length == 0) &&

                           <Button
                              primary
                              icon    = { <Add /> }
                              label   = "Добавить"
                              onClick = { () => setShow(true) }
                              style   = { { margin: "0 10px" } }
                           />

                        }

                     </Heading>

                     { show &&

                        <Layer
                           onEsc          = { () => setShow(false) }
                           onClickOutside = { () => setShow(false) }
                        >
                           <UnitCreate setShow = { setShow }/>
                        </Layer>

                     }

                     <UnitsTable />
                  </>
               ) }
            </>

      ) : errorFlag 
         ? (
            <Page404 />
         ) : (
            <Loader />
         ) }
         
      </Box>
   );
})
