import { TDevice } from "models/Device";
import { TExternalUnitsProps } from "./types";

export const screenUnit = (device: TDevice, slotImage) : TExternalUnitsProps => {

   let pinY: number;
   
   const unitStyle = {
      position: 'absolute',
      top     : '114',
      left    : device?.units.some ((unit) => unit.id_unittype == 5) ? '175' : '0', // If LAN (5)
   };

   const slotPosition = slotImage.transform?.match (/translate \(\d+\.?\d+?, (\d+)\)/i)
   const pinPosition  = slotImage.image.match      (/cy="(\d*\.?\d+?)"/i)
   const deviceSize   = device?.units.some ((unit) => unit.id_unittype == 5) ? 350 : 0

   if (slotPosition) pinY = Number (slotPosition[1]) + Number (pinPosition[1]) + 206

   return { pinY, unitStyle, deviceSize }
}