import { useEffect } from "react";
import { TActivPin } from "components/Projects/ProjectDetailCard/Hooks/useProjectEmulator";
import { TUnit } from "./types";

export const useDeviceNodeAction = (
   exRef,
   units: TUnit[],
   reactivePins,
) => {

   useEffect(() => {

      if (exRef.current/* .length */ && reactivePins) {

         if (units.some ((unit) => unit.unit.id_unittype == 49 && 
             reactivePins.some ((pin) => pin.unit == unit.unit.id_unit))) {

            const valve = exRef.current.getElementById('valveValue');
            const pump  = exRef.current.getElementById('pump'      );

            //console.log(toJS(device))
            //console.log(reactivePins)

            let pumpPin  : TActivPin ;
            let valvePin : TActivPin ;
            let valveProps = 0       ;

            /* units.forEach(unit => 
               unit.unit.slots.forEach(slot => {

                  if (slot.pins.some(pin => pin.id_pintype == 6)) {

                     pumpPin = reactivePins.find(pin => 
                        pin.pin == slot.pins.find((pin) => pin.id_pintype == 6)?.id_unitslotpin
                     );
                  }

                  if (slot.pins.find(pin => pin?.id_pintype == 9)) {

                     valvePin = reactivePins.find(pin => 
                        pin.pin == slot.pins.find((pin) => pin?.id_pintype == 9)?.id_unitslotpin
                     );
                  }
               })
            ) */

            for (const unit of units) {

               pumpPin  = reactivePins.find (pin =>

                  pin.pin === unit.unit.slots.find (slot => 
                     slot.pins.some (pin => pin.id_pintype === 6)
                  )
                  ?.pins.find (pin => pin.id_pintype === 6)?.id_unitslotpin
               )

               valvePin = reactivePins.find (pin => 
                  
                  pin.pin === unit.unit.slots.find(slot => 
                     slot.pins.some(pin => pin.id_pintype === 9)
                  )
                  ?.pins.find(pin => pin.id_pintype === 9)?.id_unitslotpin
               );
            }

            if (pumpPin) {

               if (pumpPin.value != 0) pump.setAttribute    ('class', 'rotate') ;
               else                    pump.removeAttribute ('class')           ;
            }

            if (valvePin) {

               /* switch (true) {

                  case valvePin.value < 5:
                     valveProps = 0;
                     break;

                  case valvePin.value >= 5 && 
                          valvePin.value < 10:
                     valveProps = 50;
                     break;

                  case valvePin.value >= 10:
                     valveProps = 100;
                     break;
               } */

               valveProps = valvePin.value < 5 ? 0 : valvePin.value < 10 ? 50 : 100;
               valve.innerHTML = '<text x="420" y="730" class="svgText">' + valveProps + '%</text>';
            }
         }
      }
   }, [units, reactivePins]);
}