import {
   TUnit,
   TUnitNewData,
   TUnitUpData
} from "models/Unit"
import { api } from "./client"
import { TLinkedHardware } from 'models/Hardware'

export const getUnitList = async (deviceId: number): Promise<TUnit[]> => {
   return await api.send ("GET", `/device/unit/?id_device=${deviceId}`)
}

export const getUnitItem = async (unitId: number): Promise<TUnit> => {
   return await api.send ("GET", `/device/unit/?id_unit=${unitId}`)
}

export const changeUnit = async (
   unitUpData: TUnitUpData
): Promise<TUnit> => {
   return await api.send ("PUT", "/device/unit/", unitUpData)
}

export const addNewUnit = async (
   newUnitData: TUnitNewData
): Promise<TUnit> => {
   return await api.send ("POST", "/device/unit/", newUnitData)
}

export const deleteUnit = async (
   unitId: number
): Promise<TUnit> => {
   return await api.send ("DELETE", "/device/unit/", {
      id_unit: unitId,
   })
}

export const getLinkedHardware = async (
   idUnit: number
): Promise<TLinkedHardware> => {
   return await api.send ('GET', `/device/unit/hardware/?id_unit=${idUnit}`)
}

export const saveLinkedHardware = async (
   idUnit  : number,
   hardwareId: number | null
): Promise<TLinkedHardware> => {
   return await api.send ('PUT', '/device/unit/hardware/', {
      id_unit  : idUnit,
      id_hardware: hardwareId
   })
}

export const deleteLinkedHardware = async (
   idUnit  : number
): Promise<TLinkedHardware> => {
   return await api.send ('DELETE', '/device/unit/hardware/', {
      id_unit: idUnit
   })
}
