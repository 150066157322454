import React from "react"

import { STATUS_DELETED } from "constants/globalConstants"
import { TUnit          } from "models/Unit"

import { Grid, Box, Card, CardHeader, CardBody, Text } from "grommet"
import { LinkButton     } from "../UI/LinkButton/LinkButton"
import { SlotsTable     } from "../Slots/SlotsTable"

export function InternalUnitCard ({
   unit,
   id_project
} : {
   unit      : TUnit,
   id_project: number
}) {

   return (
      <>
         { unit.id_state !== STATUS_DELETED && (

            <Grid
               rows         = { ["small", "auto"] }
               alignContent = "baseline"
               style        = { {
                  gridTemplateRows: "180px auto",
                  marginTop       : "10px"
               } }
            >
               <Box style = { { backgroundColor: "#005d8f" } }>
                  <LinkButton
                     to = { `/projects/${id_project}/device/${unit.id_device}/unit/${unit.id_unit}` }
                  >
                     <Card
                        style      = { { boxShadow: "none" } }
                        key        = { unit.id_device }
                        pad        = "medium"
                        gap        = "medium"
                        round      = "xsmall"
                        className  = { unit.id_state === STATUS_DELETED
                                       ? "background-card-deleted"
                                       : "" }
                        background = { unit.id_state === STATUS_DELETED
                                       ? "backgroundSideBar"
                                       : "baseBackground" }
                        onClick    = { () => false }
                        hoverIndicator = { "backgroundCardHover" }
                     >
                        <CardHeader
                           align   = "center"
                           justify = "start"
                        >
                           <Grid
                              fill
                              pad     = "xxsmall"
                              gap     = "xxsmall"
                              columns = { ["3/4", "1/4"] }
                           >
                              <Text
                                 size   = "medium"
                                 weight = "bold"
                              >
                                 { unit.name }
                              </Text>

                              { unit.nunit != null &&

                                 <Text
                                    size      = "medium"
                                    weight    = "bold"
                                    textAlign = "end"
                                 >
                                    { unit.nunit }
                                 </Text>
                              }
                           </Grid>
                        </CardHeader>
                        <CardBody
                           align   = "start"
                           justify = "start"
                        >
                           <Text
                              size   = "small"
                              weight = "normal"
                           >
                              { unit.remark || unit.description }
                           </Text>
                        </CardBody>

                     </Card>
                  </LinkButton>
               </Box>
               <Box
                  style = { {
                     backgroundColor: "#005d8f",
                     paddingBottom  : "10px"
                  } }
               >
                  <SlotsTable idUnit = { unit.id_unit } />
               </Box>
            </Grid>
         ) }
      </>
   )}
