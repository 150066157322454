import { useEffect, MutableRefObject, useContext } from 'react';

//import { getProjectLinks } from 'api/projectlink';

import { STATUS_DELETED } from 'constants/globalConstants';
import { TDevice } from 'models/Device';

import {
   // addEdge           ,
   // Connection        ,
   Edge              ,
   Node              ,
   ReactFlowInstance ,
   useEdgesState     ,
   useNodesState     ,
} from 'react-flow-renderer';
import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';

type TFlowElemensProps = {
   reactFlowInstance : ReactFlowInstance     ;
   reactFlowWrapper  : MutableRefObject<HTMLElement> ;
   devices?          : TDevice[]       ;
};

export const useFlowElements = (props: TFlowElemensProps) => {

   const {
      reactFlowInstance ,
      devices           ,
   } = props

   const {
      idProject   ,
      idSchematic ,
   } = useContext(ProjectContext);

   const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
   const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);

   /* const drawEdges = (links) => {

    //console.log(links)

    let edges = [];

    if (links) {

      const linkEdges = links?.reduce((acc, link) => {

        const position = link.position == null ? [] : JSON.parse(link.position)
        acc.push({id_pinlink: link.id_pinlink,
                  position: position,
                  contacts: link.contacts})
        return acc;          
      }, []);    
      
      //console.log(linkEdges)

      edges = linkEdges.reduce((acc, link) => {

        //console.log(link.position.edges);
        //console.log(link.contacts);

        if (link.position.edges && link.contacts) {

          //console.log('Front: ', link.position.contacts)
          //console.log('Back: ', link.contacts)

          const newContacts     = link.contacts.filter         (backContact  => link.position.contacts.every(frontContact => frontContact.pin != backContact.pin));
          const deletedContacts = link.position.contacts.filter(frontContact => link.contacts.every         (backContact  => frontContact.pin != backContact.pin));
          
          link.position.edges.forEach(edge => {

            if (deletedContacts.length) {
              
              deletedContacts.forEach(deletedContact => {

                if ('device-' + deletedContact.device == edge?.source &&
                    'pin-'    + deletedContact.pin    == edge?.sourceHandle) {
                  
                  newContacts.push({device: Number(edge?.target.match(/device-(\d+)/i)[1]),
                                       pin: Number(edge?.targetHandle.match(/pin-(\d+)/i)[1])})
                }
                else if ('device-' + deletedContact.device == edge?.target &&
                         'pin-'    + deletedContact.pin    == edge?.targetHandle) {

                  newContacts.push({device: Number(edge?.source.match(/device-(\d+)/i)[1]),
                                       pin: Number(edge?.sourceHandle.match(/pin-(\d+)/i)[1])})
                }
                else {

                  acc.push({...edge, data: {id_pinlink: link.id_pinlink,
                                            reactFlowInstance: reactFlowInstance,
                                            reactFlowWrapper: reactFlowWrapper,
                                            path: edge.data?.path}});
                }
              });
            }

            acc.push({...edge, data: {id_pinlink: link.id_pinlink,
                                      reactFlowInstance: reactFlowInstance,
                                      reactFlowWrapper: reactFlowWrapper,
                                      path: edge.data?.path}});
          });

          //console.log('newContacts: '    , newContacts)
          //console.log('deletedContacts: ', deletedContacts)

          newContacts.forEach(newContact => {
            
            acc.push({id: "edge-" + generateUID(),
                      source: "device-" + newContact.device,
                      sourceHandle: "pin-" + newContact.pin,
                      target: "device-" + link.position.contacts[link.position.contacts.length - 1].device,
                      targetHandle: "pin-" + link.position.contacts[link.position.contacts.length - 1].pin,
                      type: "customEdge",
                      data: {id_pinlink: link.id_pinlink,
                             reactFlowInstance: reactFlowInstance,
                             reactFlowWrapper: reactFlowWrapper,
                             path: []}});
          });
        }
        else if (link.contacts.length) {

          for (let i = 0; i < link.contacts.length - 1; i++) {

            acc.push({id: "edge-" + generateUID(),
                      source: "device-" + link.contacts[i].device,
                      sourceHandle: "pin-" + link.contacts[i].pin,
                      target: "device-" + link.contacts[i + 1].device,
                      targetHandle: "pin-" + link.contacts[i + 1].pin,
                      type: "customEdge",
                      data: {id_pinlink: link.id_pinlink,
                            reactFlowInstance: reactFlowInstance,
                            reactFlowWrapper: reactFlowWrapper,
                            path: []}});
          }
        }

        return acc;
      }, [])
    }

    //console.log(edges)
    return edges;
  } */

   useEffect (() => {

      // HOWTO: Get all position for all idSchematic

      const nodes = devices
      ?.filter(device => device.id_state != STATUS_DELETED)
      ?.reduce((acc, device: TDevice) => {

         if (device.id_state != STATUS_DELETED) {

            acc.push({
               id   : 'device-' + device.id_device?.toString() ,
               type : 'deviceNode'                             ,
               data : {
                  device,
               },
               position : {
                  x : device.locations[idSchematic]?.x || 20,
                  y : device.locations[idSchematic]?.y || 20,
               },
            });
         }

         return acc
      }, [])

      /* if (links) {

      const linkMarkers = links?.reduce((acc, link) => {

        const position = link.position == null ? [] : JSON.parse(link.position)?.markers
        acc.push({id_pinlink: link.id_pinlink,
                  position: position})

        return acc;          
      }, []);  

      const markers = linkMarkers.reduce((acc, link) => {

        link.position.forEach(marker => acc.push(marker));

        return acc;
      }, [])

      if (markers.length) {
        nodes.push(...markers)
      }
    } */

      setNodes(nodes)
   }, [idProject, idSchematic, reactFlowInstance, devices])

   /* useEffect(() => {

      getProjectLinks(idProject, idSchematic)
      .then((result) => {

         if (result.id_project       == idProject &&
             result.id_schematictype == idSchematic) {

            setNodes(drawNodes(result?.links));
            //setEdges(drawEdges(result?.links));
         }
      });
   }, [idProject, reactFlowInstance]); */

   return {
      nodes         : nodes         ,
      edges         : edges         ,
      onNodesChange : onNodesChange ,
      onEdgesChange : onEdgesChange ,
      setEdges      : setEdges      ,
   };
};
