export const getTransformedCoords = (
   x: number, 
   y: number, 
   ctm: {
      a: number,
      c: number,
      e: number,
      b: number,
      d: number,
      f: number
   }) => {
      
   const xn = x * ctm.a + y * ctm.c + ctm.e;
   const yn = x * ctm.b + y * ctm.d + ctm.f;
   return {
      x: xn,
      y: yn
   };
}