import React from "react"

import { STATUS_DELETED   } from "constants/globalConstants"
import { TUnit            } from "models/Unit"

import { unitStore        } from "store/UnitStore"
import { deviceLinkStore  } from "store/DeviceLinkStore"
import { slotStore        } from "store/SlotStore"

import { Grid, Box, Card, CardHeader, CardBody, Text } from "grommet"
import { LinkButton       } from "components/UI/LinkButton/LinkButton"
import { SlotsTable       } from "components/Slots/SlotsTable"
import { InternalUnitCard } from "./InternalUnitCard"
import { Loader           } from "components/Common/Loader"

let unitInternal: TUnit[] = [];

export function UnitCard ({
   unit,
   id_project
} : {
   unit      : TUnit,
   id_project: number
}) {

   /*const unitInternalLinkedLinks: TLinkItem["id_slotlink"][] = [];
     const internalLinkedUnits: TUnit[] = [];*/

   if (unitStore.loading || slotStore.loading || deviceLinkStore.loading) {
      <Loader />
   }
   else {

      /*slotStore.slots[unit.id_unit]?.filter((slot: TSlotItem) => slot?.isinternal == 1 && slot?.id_slotlink != null)
      .forEach((internalLinkedSlot: TSlotItem) => unitInternalLinkedLinks.push(internalLinkedSlot?.id_slotlink))

      unitInternalLinkedLinks.forEach(link => {
         internalLinkedUnits.push(internalUnits?.find(unit => {
         return (link == slotStore.slots[unit.id_unit].find(slot => slot.id_slotlink).id_slotlink)
         }))
      });*/

      unitInternal = unitStore.units.filter (unit => unit.isinternal) //&& unit.nposition == unit.nposition)

      //console.log(unitInternal)

      return (
         <>

            { unit.id_state !== STATUS_DELETED && (

               <Grid
                  rows         = { ["small", "auto"] }
                  alignContent = "baseline"
                  style        = { { gridTemplateRows: "180px auto" } }
               >
                  <Box style = { { backgroundColor: "#005d8f" } }>

                     <LinkButton
                        to = { `/projects/${id_project}/device/${unit.id_device}/unit/${unit.id_unit}` }
                     >
                        <Card
                           style      = { { boxShadow: "none" } }
                           key        = { unit.id_device }
                           pad        = "medium"
                           gap        = "medium"
                           round      = "xsmall"
                           className  = { unit.id_state === STATUS_DELETED
                                          ? "background-card-deleted"
                                          : "" }
                           background = { unit.id_state === STATUS_DELETED
                                          ? "backgroundSideBar"
                                          : "baseBackground" }
                           onClick    = { () => false }
                           hoverIndicator = { "backgroundCardHover" }
                        >
                           <CardHeader
                              align     = "center"
                              justify   = "start"
                              direction = "row"
                           >
                              <Grid 
                                 fill
                                 pad     = "xxsmall"
                                 gap     = "xxsmall"
                                 columns = { ["3/4", "1/4"] }
                              >
                                 <Text
                                    size   = "medium"
                                    weight = "bold"
                                 >
                                    { unit.name }
                                 </Text>

                                 { unit.nunit != null &&
                                    <Text
                                       size      = "medium"
                                       weight    = "bold"
                                       textAlign = "end"
                                    >
                                       { unit.nunit }
                                    </Text>
                                 }

                              </Grid>
                           </CardHeader>

                           <CardBody
                              align   = "start"
                              justify = "start"
                           >
                              <Text
                                 size   = "small"
                                 weight = "normal"
                              >
                                 { unit.remark || unit.description }
                              </Text>
                           </CardBody>

                        </Card>
                     </LinkButton>
                  </Box>

                  <Box
                     style = { { backgroundColor: "backgroundSideBar" } }
                  >
                     <SlotsTable idUnit = { unit.id_unit } />
                  </Box>

                  <Box>

                     { (unit.nunit == 0) && 
                        unitInternal.map (unit => (

                           <InternalUnitCard
                              unit       = { unit }
                              id_project = { Number(id_project) }
                              key        = { unit?.id_unit } />
                        )) 
                     }

                  </Box>
               </Grid>
            ) }
         </>
      );}
}
