import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Navigate, useLocation, useMatch } from 'react-router-dom'

import { rootStore } from 'store/RootStore'
import { workspaceStore } from 'store/WorkspaceStore'

import { AllRoutesRender } from 'AllRoutesRender'
import { isOuter } from 'data/routes'

import { Loader } from 'components/Common/Loader'
import { Login } from './containers/Auth/Login'
import { DeviceLanding } from 'components/Landing/DeviceLanding'

import { getLocalStorageItem } from 'utils/localStorage'
import { ACCESS_TOKEN } from 'constants/globalConstants'

const isExistToken = getLocalStorageItem(
   ACCESS_TOKEN,
   null
)

export const RouteChecker = observer (() => {

   const { pathname }  = useLocation ()
   const deviceLanding = useMatch ("/h/:exact")

   useEffect(() => {
      if (isExistToken) rootStore.currentUserStore.getCurrentUserData ()
   }, [])

   useEffect(() => {

      if (!rootStore.currentUserStore?.currentUserIsLoading &&
           rootStore.currentUserStore?.currentUserData?.isadmin) {
         workspaceStore.getWorkspaces ()
      }
   }, [rootStore.currentUserStore?.currentUserData])

   //console.log(outerRoutes)
   //console.log(pathname)

   if (deviceLanding) { return <DeviceLanding /> }
   else {

      if (rootStore.currentUserStore.currentUserIsLoading) {
         return <Loader />;
      }

      if (!rootStore.currentUserStore.currentUserExists &&
          !isOuter (pathname)                            &&
          !isExistToken                                  &&
          !location.pathname.includes ('/code/')) {
         return <Navigate to = '/login' />;
      }
   }

   return (
      <>
         { isOuter (pathname) 
           ? <Login /> 
           : <AllRoutesRender /> 
         }
      </>
   )
});
