import { makeAutoObservable } from "mobx"

import { TSlotItem    } from "models/Slot"
import { TUnit        } from "models/Unit"
import { errorHandler } from "utils/errorHandler"

import { getAvaliableSlotsList, getDeviceSlotList, getSlotsList } from "api/slot"

type slots = {
   [index: number]: TSlotItem[]
}

class SlotStore {
   loading      = true
   slots: slots = {}

   constructor () {
      makeAutoObservable(this)
   }

   setSlots (slots: TSlotItem[], idUnit: number) {
      this.slots[idUnit] = slots
      //console.log ("Slots loaded for unit: " + idUnit) 
      this.loading = false
   }

   getSlots = async (idUnit: number) => {
      try {
         const result = await getSlotsList(idUnit)
         this.setSlots (result, idUnit)
      } catch (err) {
         errorHandler (err)
      }
   }

   getAvaliableSlots = (units: TUnit[]) => {
      units.forEach (unit => {
         this.getSlots (Number (unit.id_unit))
      })
      this.loading = false
   }

   getAvaliableSlotsForLink = async (idSlot) => {
      const response: TSlotItem[] = await getAvaliableSlotsList (idSlot)
      return response
   }

   getAllSlots = async (units: TUnit[]) => {
      units?.forEach (async unit => {
      //console.log ("Load slots for unit: " + unit.id_unit)
         await this.getSlots (Number (unit.id_unit))
      })
   }

   getDeviceSlots = async (idDevice: number) => {
      const result = await getDeviceSlotList (idDevice)

      const slots = result.reduce ((acc, slot: TSlotItem) => {

         if (acc[slot.id_unit]) {
            acc[slot.id_unit] = [...acc[slot.id_unit], slot]
         } else acc[slot.id_unit] = []
         
         return acc
      }, [])

      slots.forEach ((slots, id_unit) => {
         this.setSlots (slots, id_unit)
      })
   }
}

export const slotStore = new SlotStore ()
