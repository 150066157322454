import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'

import { projectLinkStore } from 'store/ProjectLinkStore'

import { Tip } from 'grommet'
import { Handle } from 'react-flow-renderer'

import { TPin } from 'components/Flow/Node/hooks/types'

import { TProjectLinkItem } from 'models/ProjectLink'
import { EmulatorContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard'
import { PinLink } from './components/PinLink'
import { PinValue } from './components/PinValue'
import { PinContextMenu } from './PinContextMenu/PinContextMenu'

/* type TPinelectricalType = {
   1: "Вход",
   2: "Выход",
   3: "Вход/Выход",
   4: "Пассивный",
   5: "Питание",
   6: "Земля"
} */

type TContextPin = {
   unit : number ;
   pin  : number ;
};

export type TStyleProps = {
   pin   : CSSProperties, 
   link  : CSSProperties,
   name  : CSSProperties,
   value : CSSProperties,
}

const Style32 = {
   width : 20,
   height: 20,
};

const Style36 = {
   width : 13.3,
   height: 13.3,
};

const defaultStyle: TStyleProps = {
   pin: {
   },
   link: {
      position    : 'absolute',
      width       : 3         ,
      height      : 90        ,
   },
   name: {
      whiteSpace  : 'nowrap'  ,
      zIndex      : 1         ,
   },
   value: {
      position    : 'absolute',
      width       : 59        ,
      borderRadius: '3px'     ,
      zIndex      : 1         ,
   },
};

export const Pin = observer ((props: {
   position,
   device,
   pin: TPin,
   style,
}) => {

   const {
      position,
      device,
      pin,
   } = props;

   const {
      activePins,
      reactivePins,
   } = useContext (EmulatorContext)

   const [pinBackground  , setPinBackground  ] = useState <string>           ()            ;
   const [valueBackground, setValueBackground] = useState <string>           ('background');
   const [style          , setStyle          ] = useState <TStyleProps>      (defaultStyle);
   const [pinLink        , setPinLink        ] = useState <TProjectLinkItem> ()            ;
   const [pinValue       , setPinValue       ] = useState <number>           ()            ;

   const [contextPinMenu , setContextPinMenu ] = useState                    (false)       ;
   const [contextPin     , setContextPin     ] = useState<TContextPin>       ()            ;

   const pinTargetRef = useRef (null);

   useEffect(() => {

      const pinLink = projectLinkStore.links?.find(link =>
         link.contacts.some (contact => contact.pin == pin.pin.id_unitslotpin)
      )

      setPinLink (pinLink || null);

   }, [projectLinkStore.links]);

   useEffect(() => {
         
      switch (pin.pin.id_pinelectricaltype) {
         case 1:
            setPinBackground ('green') ;
            break;
         case 2:
            setPinBackground ('red')   ;
            break;
         case 3:
            setPinBackground ('blue')  ;
            break;
         case 4:
            setPinBackground ('yellow');
            break;
         case 5:
            setPinBackground ('brown') ;
            break;
         case 6:
            setPinBackground ('black') ;
            break;
      }
   }, [pin]);

   useEffect(() => {
         
      setValueBackground('background');

      const aPin = activePins  ?.find (aPin => aPin?.pin == pin.pin.id_unitslotpin);
      const rPin = reactivePins?.find (rPin => rPin?.pin == pin.pin.id_unitslotpin);

      if (aPin) setPinValue (aPin.value);
      if (aPin && aPin?.value != '0') setValueBackground ('blue');

      if (rPin) setPinValue (rPin.value); 
      if (rPin && rPin?.value != '0') setValueBackground ('yellow');

   }, [pin, activePins, reactivePins]);

   useEffect(() => {

      let a = {};
      if ([39,40,43,44,45,46].includes (pin.slotType)) a = Style36
      else                                             a = Style32

      if (position == 'top') {
            
         setStyle({
            pin: {
               ...style.pin,
               ...props.style,
               background: pinBackground,
               ...a
            },
            link: {
               ...style.link,
               left      : pin.unitPos / 2 + pin.slotPos / 2 + pin.pinX / 2 - 2,
               top       : -70,
            },
            name: {
               ...style.name,
               transform : 'rotate(-90deg) translate(-40px, -12px)',
            },
            value: {
               ...style.value,
               left      : pin.unitPos / 2 + pin.slotPos / 2 + pin.pinX / 2 - 30,
               top       : -40,
               transform : 'rotate(-90deg)',
            },
         });
      } else {
            
         setStyle({
            pin: {
               ...style.pin,
               ...props.style,
               background: pinBackground,
               ...a
            },
            link: {
               ...style.link,
               left     : pin.unitPos / 2 + pin.slotPos / 2 + pin.pinX / 2 - 2,
               bottom   : -70,
            },
            name: {
               ...style.name,
               transform: 'rotate(90deg) translate(20px, -12px)',
            },
            value: {
               ...style.value,
               left     : pin.unitPos / 2 + pin.slotPos / 2 + pin.pinX / 2 - 30,
               transform: 'rotate(90deg)',
            },
         });
      }
   }, [position, pin, pinBackground, pinValue, pin.slotType]);

   const pinMenu = (e, pin) => {

      e.preventDefault ();
      pinTargetRef.current = e.target;

      setContextPin ({
         unit: pin.nunit,
         pin : pin      ,
      });
      setContextPinMenu (true);
   };

   //console.log (toJS (pin))
   //console.log(pinValue)
   //console.log('activePins: ', activePins)
   //console.log('reactivePins: ', reactivePins)
   //console.log(highlightPins)
   //console.log(contextPin)
   //console.log(toJS(pinLink))
   //console.log(style)
   //console.log (pin.slotType)

   return (
      <>
         <Tip
            /* content={`${pin.pin.npin} - ${pin.pin.typename} (${pin.pin.typedescription})\r\n
                  PORT[${pin.nunit}][${pin.nport}]`} */
            content = { `${pin.pin?.typedescription}` 
                           + (pin.nunit !== undefined 
                              ? ` - PORT[${pin.nunit}][${pin.nport}]` 
                              : '') }
         >
            <Handle
               id            = { 'pin-' + pin.pin.id_unitslotpin.toString() }
               type          = 'source'
               position      = { position                   }
               style         = { style?.pin                 }
               onContextMenu = { (e) => pinMenu(e, pin.pin) }
               //type={pin.pin.isin ? ("target") : ("source")}
            />
         </Tip>

         { pinLink && 

               <PinLink
                  pin          = { pin }
                  pinLink      = { pinLink }
                  defaultStyle = { style }
                  position     = { position }
               />
         }

         { pinValue != undefined && 

               <PinValue
                  pin             = { pin }
                  pinValue        = { pinValue }
                  valueBackground = { valueBackground }
                  defaultStyle    = { style }
                  position        = { position }
               />
         }

         { (contextPinMenu && 
               pinTargetRef.current && 
               contextPin) &&
              
                 <PinContextMenu
                    targetRef         = { pinTargetRef.current }
                    device            = { device               }
                    apin              = { pin                  }
                    setContextPinMenu = { setContextPinMenu    }
                 />
         }
      </>
   );
}
);
