import React, {
   useContext,
   useEffect,
   useState
} from "react"

import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { projectStore } from "store/ProjectsStore"

import { TProjectItem } from "models/Project"

import {
   Box,
   Button,
   Heading,
   ResponsiveContext,
   Text
} from "grommet"
import { Edit, Trash } from "grommet-icons"
import { ProjectDeleteLayer } from "components/Projects/ProjectDetailCard/ProjectDeleteLayer"
import { Loader } from "components/Common/Loader"
import { LinkButton } from "components/UI/LinkButton/LinkButton"

export const PageTitle = observer (() => {

   const size = useContext (ResponsiveContext)
   const { idProject } = useParams ()
   const [project, setProject] = useState<TProjectItem> ()

   // Modal control
   const [open, setOpen] = useState<boolean> ()
   const onOpen  = () => setOpen (true)
   const onClose = () => setOpen (undefined)

   useEffect (() => {
      setProject (projectStore.projects
      .find((project) => project.id_project == Number(idProject)))
   })

   if (projectStore.loading || !project) {

      return <Loader size = "small"/>
   }
   else {

      return (
         <>
            { size == 'large' &&

               <Box 
                  direction = "column" 
                  align     = "start" 
                  alignSelf = "center"
                  style     = { {
                     textOverflow: "ellipsis",
                     whiteSpace  : "nowrap",
                     overflow    : "hidden",
                     maxWidth    : 360
                  } }
               >
                  <Heading
                     margin    = "none"
                     level     = { 3 } 
                     textAlign = 'start'
                  >
                     { project ? project.name : 'Название проекта' }
                  </Heading>

                  <Text 
                     size = "small"
                  >
                     <b>Описание: </b> { project.remark }<br />
                  </Text>
               </Box>
            }

            <Box
               direction = "row"
               align     = "center"
            >
               <LinkButton
                  to    = { `/projects/edit/${project.id_project}` }
                  state = { { name: project.name, remark: project.remark } }
               >
                  <Button
                     secondary
                     icon  = { <Edit /> }
                     color = "brand"
                  />
               </LinkButton>

               <Button
                  secondary
                  icon    = { <Trash color = "buttonDelete" /> }
                  color   = "buttonDelete"
                  onClick = { onOpen }
               />
            </Box>

            { open &&

               <ProjectDeleteLayer
                  closeFunc = { onClose }
                  projectId = { project.id_project }
               />
            }
         </>
      )
   }
})