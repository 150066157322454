import { TUnitType } from "../models/UnitType";
import { TDeviceItem } from "../models/Device";
import { TSlotItem } from "../models/Slot";
import { api } from "./client";

export const getUnitTypeList = async (): Promise<TUnitType[]> => {
   return await api.send("GET", "/device/unit/type/");
};

export const getUnitType = async (
   unitTypeId: TUnitType["id_unittype"]
): Promise<TUnitType> => {
   return await api.send("GET", `/device/unit/type/?id_unittype=${unitTypeId}`);
};

export const getAvaliableUnitTypeListFromUnitSlot = async (
   unitSlotId: TSlotItem["id_unitslot"]
): Promise<TUnitType[]> => {
   return await api.send("GET", `/device/unit/type/?id_unitslot=${unitSlotId}`);
};

export const getAvaliableUnitTypeListFromSlotType = async (
   slotTypeId: TSlotItem["id_slottype"]
): Promise<TUnitType[]> => {
   return await api.send("GET", `/device/unit/type/?id_slottype=${slotTypeId}`);
};

export const getAvaliableUnitTypeListFromDevice = async (
   idDevice: TDeviceItem["id_device"]
): Promise<TUnitType[]> => {
   return await api.send("GET", `/device/unit/type/?id_device=${idDevice}`);
};