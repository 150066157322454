import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { TPin } from '../Node/hooks/types';

import { ProjectContext } from 'components/Projects/ProjectDetailCard/ProjectDetailCard';
import { Position } from "react-flow-renderer";

import { Pin } from './components/Pin';
import { Button } from './components/Button';
import { Led } from './components/Led';
import { Screen } from './components/Screen';

export const HOCPin = observer(({
   position,
   device,
   pin,
   slotType,
   coord
} : {
   position?: Position,
   device,
   pin: TPin,
   slotType?,
   coord?
}) => {

   const { idSchematic   } = useContext (ProjectContext);
   const [style, setStyle] = useState <CSSProperties>({});

   useEffect(() => {

      if (coord) {
         setStyle({
            ...style,
            transform: "translate(-50%, -50%)",
            left     : coord.x,
            top      : coord.y
         })
      }
      else {
         switch (position) {

            case Position.Top:
               //console.log(pin.pinY)
               setStyle({
                  ...style,
                  left: pin.unitPos / 2 + pin.slotPos / 2 + pin.pinX / 2,
                  top : pin.pinY / 2,
               })
               break;
         
            case Position.Bottom:
               setStyle({
                  ...style,
                  left  : pin.unitPos / 2 + pin.slotPos / 2 + pin.pinX / 2,
                  bottom: pin?.pinY / 2,
               })
               break;

            case Position.Left:
               //console.log(pin.unitPos)
               //console.log(pin.slotPos)
               setStyle({
                  ...style,
                  top : pin.unitPos / 2 + pin.slotPos / 2 + pin.pinY / 2,
                  left: pin?.pinX / 2,
               })
               break;

            case Position.Right:
               setStyle({
                  ...style,
                  top: pin.unitPos / 2 + pin.slotPos / 2 + pin.pinY / 2,
                  right : pin?.pinX / 2,
               })
               break;

            default:
               break;
         }
      }

   }, [position, pin]);

   switch (idSchematic) {

      case 1:

         if (style.top || style.bottom) {

            switch (slotType) {

               case 27:
                  return (
                     <Button
                        position = { position }
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )

               case 28:
                  return (
                     <Led
                        position = { position }
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )

               case 29:
                  return (
                     <Screen
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )

               case 32:
               case 36:

                  switch (pin.pin.id_pintype) {

                     case 27:
                        //console.log('Button')
                        return (
                           <Button
                              position = { position }
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )

                     case 28:
                        //console.log('LED')
                        return (
                           <Led
                              position = { position }
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )

                     case 29:
                        //console.log('Screen')
                        return (
                           <Screen
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )      
                     
                     default:
                        return (
                           <Button
                              position = { position }
                              device   = { device   }
                              pin      = { pin      }
                              style    = { style    }
                           />
                        )
                  }

               default:
                  return (
                     <Pin
                        position = { position }
                        device   = { device   }
                        pin      = { pin      }
                        style    = { style    }
                     />
                  )
            }
         }
         else return <></>

         /* case 2:
            
            return (
               <CircuitPin
                  position = { position }
                  device   = { device   }
                  pin      = { pin      }
                  style    = { style    }
               />
            ) */
   }

});
