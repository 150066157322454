import React, { FC } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { LayoutForInnerRoute } from './layouts/LayoutForInnerRoute';
import { MainComponent } from './components/Main/Main';
import { SettingComponent } from './components/User/Setting';
import { BillingComponent } from './components/Billing/Billing';
import { NotifiComponent } from './components/UI/Notifi/Notifications';
import { HardwareComponent } from './components/Hardware/Hardware';
import { ProjectsTable } from './components/Projects/ProjectsTable';
import { ProjectDetailCard } from './components/Projects/ProjectDetailCard/ProjectDetailCard';
import { ProjectForm } from './components/Projects/ProjectForm/ProjectForm';
import { DevicePage } from './components/Devices/DevicePage';
import { DeviceCreate } from './components/Devices/DeviceCreate';
import { DeviceEdit } from './components/Devices/DeviceEdit';
import { DeviceEditorProvider, UnitEditorProvider } from './components/Editor/EditorEnvironment';
import { UnitPage } from './components/Units/UnitPage';
import { UnitEdit } from './components/Units/Components/UnitEdit';
import { Page404 } from './components/Common/Page404';
import { DeviceLanding } from 'components/Landing/DeviceLanding';

export const AllRoutesRender: FC = () => {
   return (
      <Routes>
         <Route
            path    = ''
            element = { <LayoutForInnerRoute /> }
         >
            <Route
               path    = '/'
               element = { <MainComponent /> }
            />
            <Route
               path    = '/projects'
               element = { <Outlet /> }
            >
               <Route
                  path    = ':idProject'
                  element = { <Outlet /> }
               >
                  <Route
                     index
                     element = { <ProjectDetailCard /> }
                  />
                  <Route
                     path    = 'device/'
                     element = { <Outlet /> }
                  >
                     <Route
                        path    = ':idDevice/'
                        element = { <Outlet /> }
                     >
                        <Route
                           index
                           element = { <DevicePage /> }
                        />
                        <Route
                           path    = 'edit/'
                           element = { <DeviceEdit /> }
                        />
                        <Route
                           path    = 'code/'
                           element = { <DeviceEditorProvider /> }
                        />
                        <Route
                           path    = 'unit/'
                           element = { <Outlet /> }
                        >
                           <Route
                              path    = ':idUnit/'
                              element = { <Outlet /> }
                           >
                              <Route
                                 index
                                 element = { <UnitPage /> }
                              />
                              <Route
                                 path    = 'edit/'
                                 element = { <UnitEdit /> }
                              />
                              <Route
                                 path    = 'code/'
                                 element = { <UnitEditorProvider /> }
                              />
                           </Route>
                        </Route>
                     </Route>
                     <Route
                        path    = { 'new/' }
                        element = { <DeviceCreate /> }
                     />
                  </Route>
               </Route>
               <Route
                  path    = 'new'
                  element = { <ProjectForm /> }
               />
               <Route
                  path    = 'edit/:idProject'
                  element = { <ProjectForm /> }
               />
               <Route
                  index
                  element = { <ProjectsTable /> }
               />
            </Route>
            <Route
               path    = '/hardware'
               element = { <HardwareComponent /> }
            />
            <Route
               path    = '/setting'
               element = { <SettingComponent /> }
            />
            <Route
               path    = '/notifications'
               element = { <NotifiComponent /> }
            />
            <Route
               path    = '/billing'
               element = { <BillingComponent /> }
            />
            <Route
               path    = '/h/:externalUID'
               element = { <DeviceLanding /> }
            />
         </Route>
         <Route
            path    = '*'
            element = { <Page404 /> }
         />
      </Routes>
   )
}
