import React from "react";

import { Box, Text, } from "grommet"
import {
   StatusGood,
   StatusWarning,
   StatusCritical,
   StatusUnknown
} from "grommet-icons"

import { FilterControls  } from 'components/Filter/FilterControls'
import { FiltersProvider } from 'components/Filter/FiltersContext'
import { ServerResults   } from "./ServerResults"
import { hardwareStore   } from "store/HardwareStore"

// Атрибуты, доступные как фильтры
const filtersConfig = [
   /* {
      property  : 'isonline',
      label     : 'Online',
      filterType: 'CheckBoxGroup',
      render    : datum => (datum 
                            ? <Text color = "success">В сети   </Text>
                            : <Text color = "error">  Не в сети</Text>)
   }, */
   {
      property  : 'id_state',
      label     : 'Статус',
      filterType: 'CheckBoxGroup',
      render    : datum => {

         switch (datum) {
            case '0' : return (
               <Text color = "info">
                  <StatusUnknown
                     color = "info"
                     style = { { verticalAlign: 'bottom' } }
                  /> 
                  { ' Новый' }
               </Text>
            );

            case '1' : return (
               <Text color = "success">
                  <StatusGood
                     color = "success"
                     style = { { verticalAlign: 'bottom' } }
                  />
                  { ' Активен' }
               </Text>
            );

            case '2' : return (
               <Text color = "warning">
                  <StatusWarning
                     color = "warning"
                     style = { { verticalAlign: 'bottom' } }
                  />
                  { ' Заблокирован' }
               </Text>
            );

            case '3' : return (
               <Text color = "error">
                  <StatusCritical
                     color = "error"
                     style = { { verticalAlign: 'bottom' } }
                  />
                  { ' Удален' }
               </Text>
            );

            default: return '-'
         }}
   },
];

export const FilterServers = () => (
  
   <FiltersProvider>
      <Box gap = "medium">

         <FilterControls
            data         = { hardwareStore.hardwares }
            filters      = { filtersConfig }
            primaryKey   = "id"
            searchFilter = { { placeholder: 'Поиск' } }
            /* configure={
              <Button icon={<Splits />} kind="toolbar" tip="Configure columns" />
            } */
         />
         <ServerResults />

      </Box>
   </FiltersProvider>
);