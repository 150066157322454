import { TSlotItem, TSlotType } from "models/Slot";
import { api } from "api/client";

export const getSlotsList = async (
   unitId: number
): Promise<TSlotItem[]> => {
   return await api.send("GET", `/device/unit/slot/?id_unit=${unitId}`);
};

export const getDeviceSlotList = async (
   idDevice: number
): Promise<TSlotItem[]> => {
   return await api.send("GET", `/device/unit/slot/?id_device=${idDevice}`);
};

export const getAvaliableSlotsList = async (
   slotId: number
): Promise<TSlotItem[]> => {
   return await api.send("GET", `/device/unit/slot/?action=FindAvailableSlots&id_unitslot=${slotId}`);
};

export const getSlotItem = async (
   slotId: number
): Promise<TSlotItem> => {
   return await api.send("GET", `/device/unit/slot/?id_unitslot=${slotId}`);
};

export const getSlotType = async (
   slotTypeId: number
): Promise<TSlotType> => {
   return await api.send("GET", `/device/unit/slot/?id_slottype=${slotTypeId}`);
};